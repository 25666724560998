import React from 'react';

export default class GenTableDisplay extends React.Component {
  updateSort = (newSortValue) => {
    if (!newSortValue) { return null }

    let newSortDirection = this.props.sort_by === newSortValue && this.props.sort_order === 'ASC' ? 'DESC' : 'ASC'

    this.props.setListSort(newSortValue, newSortDirection)
    this.props.resetListPages()
    this.props.readListPage()
  };

  onItemClick = (item) => {
    const isSelected = this.props.selected.some(sItem => sItem.id === item.id);
    let newSelectedItems = isSelected ? this.props.selected.filter(sItem => sItem.id !== item.id) : this.props.selected.concat([item])
    this.props.setListSelected(newSelectedItems)
  };

  render() {
    let tableHeaderCells = this.props.columns.map((column) => {
      let orderClass
      let className = ""
      if (column.center_column_header) {
        className = className + " edit-documents-access-table-centered-header"
      }

      if (column.sort_column) {
        className = className + " sort_cell"
        if (column.sort_column !== this.props.sort_by) {
          orderClass = "fa fa-sort"
        } else if (this.props.sort_order === 'ASC') {
          orderClass = "fa fa-sort-asc"
        } else {
          orderClass = "fa fa-sort-desc"
        }
      }

      if (column.column_width) {
        className = className  + ` ${column.column_width}-column-size`
      }

      return <td key={column.key || column.title} onClick={() => {this.updateSort(column.sort_column)}} className={className}>
        {column.title}
        <span>  <i className={orderClass}></i></span>
      </td>
    })

    let tableHeader = <tr key='table-header'>
      <td key="select-box" className="select-box"></td>
      {tableHeaderCells}
    </tr>

    let itemRows = (this.props.listData || []).map(item => {
      let cells = this.props.columns.map(column => {
        let className = (column.calculated_class_name) ? column.calculated_class_name(item) : ""
        return <td key={column.key || column.title} className={className}>{column.calculated_value(item)}</td>
      })

      let isSelected = this.props.selected.some(sItem => sItem.id === item.id)

      return <tr key={item.id} className={isSelected ? "bg-sky-100" : ""}>
        <td key="select-box" className="select-box">
          <input
            type="checkbox"
            checked={isSelected}
            onChange={() => {this.onItemClick(item)}} />
        </td>
        {cells}
      </tr>
    })

    return <div className="gen-table">
      <table>
        <thead>
          {tableHeader}
        </thead>
        <tbody>
          {itemRows}
        </tbody>
      </table>
    </div>
  }
}
