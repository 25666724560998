import React from 'react';

import GenButton from 'Gen/Button';

export default class GenSelectBar extends React.Component {
  handleSelectAll = (e) => {
    e.preventDefault();

    this.props.selected.length > 0
      ? this.props.setListSelected([])
      : this.props.setListSelected((this.props.listData || []));
  }

  render() {
    const selectText = `Select ${this.props.listData ? this.props.listData.length + " " : ""} Shown`

    return <div className="gen-select-list-buttons">
      <div className="gen-select-list-buttons-left flex gap-2 items-baseline">
        <GenButton name={this.props.selected.length > 0 ? "Deselect All" : selectText} onClick={this.handleSelectAll} highlighted small/>
        <span>{this.props.selected.length} {this.props.itemType} selected</span>
        <span>{this.props.selectionDescription && this.props.selectionDescription(this.props.selected)}</span>
      </div>
      <div className="gen-select-list-buttons-right">
        {this.props.selectExtraButtons}
      </div>
    </div>
  }
}
