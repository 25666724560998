import React from 'react';

import GenList from 'Gen/List'

import DocumentSummary from 'Document/Summary'

export default class CategoryDocumentList extends React.Component {
  render() {
    return(
        <GenList
          itemsURL={"/categories/" + this.props.category_id + "/category_documents"}
          gridView
          itemView={category_document =>
            <DocumentSummary
              document={category_document}
              key={category_document.id}
              rootDocumentSetId={this.props.rootDocumentSetId}
              currentUser={this.props.currentUser}
              currentRoleDefinition={this.props.currentRoleDefinition}
              categoryId={this.props.category_id}
            />
          }
          search
        />
    );
  }
}
