import React from 'react';

// GenCheckboxes :: {
//   options: [String | DetailedOptions]
//   onChange: function
// }
//
// DetailedOptions :: {
//   title: String,
//   value: Any
// }

export default class GenCheckboxesFilter extends React.Component {
  render() {
    let classes = 'gen-list-filter-2'
    let title = <h5>{this.props.title}</h5>

    if (this.props.displayLikeFilterGroup) {
      classes += ' like-checkbox-filters'
      title = <h4>{this.props.title}</h4>
    }

    return(
      <div className={classes}>
        <div className="gen-list-filter-title" onClick={() => {this.props.onChange(this.props.value ? null : [])}}>
          {title}
          <div className="cursor-pointer"><span>{this.props.value ? "All" : "Filter"}</span></div>
        </div>
        {this.props.value && <div className="gen-list-filter-options">
          {this.props.options.map((option) => {
            let optionName, optionValue
            if (typeof option === 'object') {
              optionName = option.displayName;
              optionValue = option.value;
            } else {
              optionName = option
              optionValue = option
            }

            let key = this.props.title + "-" + optionName

            let isChecked = this.props.value.includes(optionValue)
            let onChange = () => {
              let newValue = this.props.value
              if (isChecked) {
                newValue = newValue.filter((o) => {return o !== optionValue})
              } else {
                newValue = newValue.concat([optionValue])
              }

              this.props.onChange(newValue)
            }

            return(
              <div key={key} className="gen-list-filter-option">
                <label>
                  <input
                    id={key}
                    name={key}
                    type="checkbox"
                    checked={isChecked}
                    onChange={onChange}
                  />
                  {optionName}
                </label>
              </div>
            )
          })}
        </div>}
      </div>
    )
  }
}
