import React from 'react';

import connectToList from 'store/list'

import GenMore from 'Gen/More'
import GenControlsBar from 'Gen/ControlsBar'
import GenSearchBox from 'Gen/SearchBox'
import GenSortButtons from 'Gen/SortButtons'

const ENDPOINT = 'institutions'

class InstitutionList extends React.Component {
  componentDidMount() {
    this.props.institutions.mountList(ENDPOINT)
  }

  render() {
    let items = (this.props.institutions.listData || []).map(item => {
      return (
        <div className="panel" key={item.id}>
          <h1>
            <a href={"/institutions/" + item.id}>{item.name}</a>
          </h1>
        </div>
      )
    })

    return (
      <div>
        <GenControlsBar>
          <GenSearchBox
            {...this.props.institutions}
          />
          <GenSortButtons
            {...this.props.institutions}
            sortOptions={[
              {
                "title": "Name",
                "value": "institutions.name"
              }
            ]}
          />
        </GenControlsBar>
        {items}
        <GenMore
          {...this.props.institutions}
          shouldInfiniteScroll
        />
      </div>
    )
  }
}

export default connectToList(InstitutionList, 'institutions')
