import React from 'react';

import classNames from 'classnames'

import GenMore from 'Gen/More'
import GenCheckboxFilter from 'Gen/CheckboxFilter'
import GenCheckboxesFilter from 'Gen/CheckboxesFilter'
import GenDirectionalButtons from 'Gen/DirectionalButtons'
import GenFilterGroup from 'Gen/FilterGroup'
import GenButton from 'Gen/Button'
import GenLoadingSpinner from 'Gen/LoadingSpinner'
import GenYMWDFilter from 'Gen/YMWDFilter'

import { fullURL } from 'src/url'

function filterKeys(object, keys) {
    var result = {};
    for (var key in object)
        if (keys.indexOf(key) > -1)
            result[key] = object[key];
    return result;
}

const historyToStore = [
  "searchValue",
  "sortValue",
  "sortDirection",
  "pageNumber",
  "filterValues",
  "activeCheckboxFilters"
]

function renderFilters(filters, filterValues, setStateFilterValues) {
  return filters.map((filter) => {
    let keyToUse = filter.key || filter.title

    let filterValueChangeFunction = (filterValue) => {
      let newFilterValues = filterValues
      newFilterValues[keyToUse] = filterValue
      setStateFilterValues(newFilterValues)
    }

    switch (filter.type) {
      case "filter_group":
        let filterChildren = renderFilters(filter.filters, filterValues, setStateFilterValues)

        return <GenFilterGroup
          key={keyToUse}
          title={filter.title}
        >
          {filterChildren}
        </GenFilterGroup>
      case 'checkbox':
        return <GenCheckboxFilter
          key={keyToUse}
          title={filter.title}
          value={filterValues[keyToUse]}
          onChange={filterValueChangeFunction}
        />
      case 'checkboxes':
        return <GenCheckboxesFilter
          key={keyToUse}
          title={filter.title}
          displayLikeFilterGroup={filter.displayLikeFilterGroup}
          options={filter.options}
          value={filterValues[keyToUse]}
          onChange={filterValueChangeFunction}
        />
      case 'ymwd':
        return <GenYMWDFilter
          key={keyToUse}
          title={filter.title}
          displayLikeFilterGroup={filter.displayLikeFilterGroup}
          value={filterValues[keyToUse]}
          onChange={filterValueChangeFunction}
        />
      default:
        return null
    }
  })
}

function gerenateFilterWhereQuery(filters, filterValues) {
  return filters.map((filter) => {
    let keyToUse = filter.key || filter.title

    switch (filter.type) {
      case "filter_group":
        return gerenateFilterWhereQuery(filter.filters, filterValues)
      case 'checkbox':
        switch (filter.dataType) {
          case "MDATA":
            if (filterValues[keyToUse]) {
              const mdataColumn = filter.mdataTable ? `${filter.mdataTable}.mdata` : "mdata"
              return ["@>", mdataColumn, {[filter.mdataKey || keyToUse]: filter.mdataValue}]
            }
        }
        break;
      case 'checkboxes':
        let values = filterValues[keyToUse]
        if (values && values.length > 0) {
          let whereArrays = values.map((value) => {
            switch (filter.dataType) {
              case "MDATA":
                if (filter.dataCount === "MDATA_MANY") {
                  value = [value]
                }

                const mdataColumn = filter.mdataTable ? `${filter.mdataTable}.mdata` : "mdata"
                return ["@>", mdataColumn, {[keyToUse]: value}]
              case "REGULAR":
                return ["=", keyToUse, value]
              case "BOOLEAN":
                const opp = value ? "is_not_null" : "is_null"
                return [opp, keyToUse]
            }
          })

          if (whereArrays.length > 0) {
            whereArrays = ["or"].concat(whereArrays)
          }

          return whereArrays
        }
        break;
      case 'ymwd':
        let value = filterValues[keyToUse]
        if (value) {
          const mdataColumn = filter.mdataTable ? `${filter.mdataTable}.mdata` : "mdata"
          const mdataKey = `${mdataColumn}->>${keyToUse}::int`
          return ['and', ['>=', mdataKey, value.min], ['<=', mdataKey, value.max]]
        }
        break;
    }

    return null
  }).filter((v) => {return v !== null && v.length != 0})
}

export default class GenSidebarList extends React.Component {
  // React.PropTypes
  // propTypes: {
  //   // ids:
  //   // notIds:
  //   // itemsURL:
  //   // search:
  //   // sortOptions:
  //   // filterOptions:
  // },
  //
  static defaultProps = {
    historyPersistance: false,
    defaultItemsPerPage: 12,
    shouldInfiniteScroll: true
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      currentItems: null,
      itemsPerPage: props.defaultItemsPerPage,
      pageNumber: 0,
      gettingNewPage: false,
      itemsLastRecieved: null,
      searchValue: "",
      searchTimer: null,
      sortValue: null,
      sortDirection: null,
      activeCheckboxFilters: [],
      filterValues: {},
      infiniteScrollRandomKey: Math.floor(Math.random() * 1000000),
      showMobileFilters: false
    };

    const history = this.getHistoryState();

    this.state = {
      ...defaults,
      ...history
    };
  }

  componentDidMount() {
    this.getUpdatedList();

    if (this.props.shouldInfiniteScroll) {
      window.addEventListener("scroll", this.checkVisibilityForInfiniteScroll);
      setTimeout(this.checkVisibilityForInfiniteScroll,500);
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.searchTimer);
    }

    window.removeEventListener("scroll", this.checkVisibilityForInfiniteScroll);
  }

  handleToggleMobileFilters() {
    this.setState({showMobileFilters: !this.state.showMobileFilters})
  }

  /////////////
  // HISTORY //
  /////////////
  getHistoryState = () => {
    let state = history.state;
    if (state === null) {
      state = {};
    }
    return(filterKeys(state, historyToStore));
  };

  setHistoryState = () => {
    const currentState = history.state;
    const newPartial = filterKeys(this.state, historyToStore);
    const newState = {...currentState, ...newPartial};

    history.replaceState(newState, document.title);
  };

  ////////////
  // SEARCH //
  ////////////
  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value,
                   currentItems: null,
                   gettingNewPage: false,
                   pageNumber: 0});

    if (this.state.searchTimer) { clearTimeout(this.state.searchTimer); }
    this.setState({searchTimer: setTimeout(this.updateSearchWithTimer, 1000)});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSearchWithTimer = () => {
    this.getUpdatedList();
    this.setState({timer: null});
  };

  //////////
  // SORT //
  //////////
  updateSort = (newSortValue, newSortDirection) => {
    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection,
                    currentItems: null,
                    gettingNewPage: false,
                    pageNumber: 0 }, this.getUpdatedList);
  };

  ////////////
  // FILTER //
  ////////////
  handleCheckboxFilterChange = (that, filterName) => {
    return(function(event){
      let isChecked = event.target.checked;

      let newActiveCheckboxFilters = that.state.activeCheckboxFilters;
      if (isChecked) {
        newActiveCheckboxFilters = newActiveCheckboxFilters.concat([filterName])
      } else {
        newActiveCheckboxFilters = newActiveCheckboxFilters.filter((val) => val != filterName)
      }

      that.setState({ activeCheckboxFilters: newActiveCheckboxFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  handleFilterChange = (newFilterValues) => {
    this.setState({
      filterValues: newFilterValues,
      currentItems: null,
      gettingNewPage: false,
      pageNumber: 0
    }, this.getUpdatedList)
  };

  moreClick = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1,
                    gettingNewPage: true}, this.getAdditionalPage);
  };

  dataForQuery = () => {
    let data = {};

    if (this.props.ids) {
      data.ids = this.props.ids;
    }

    if (this.props.notIds) {
      data.not_ids = this.props.notIds;
    }

    if (this.state.searchValue && this.state.searchValue.trim() !== "") {
      data.search = this.state.searchValue;
    }

    if (this.state.sortValue) {
      data.sort_order = (this.state.sortDirection === "descending") ? "DESC" : "ASC";
      data.sort_by = this.state.sortValue;
    }


    if (this.state.itemsPerPage) {
      if (this.state.currentItems === null) {
        data.page = 0;
        data.per_page = (this.state.pageNumber + 1) * this.state.itemsPerPage;
      } else {
        data.page = this.state.pageNumber;
        data.per_page = this.state.itemsPerPage;
      }
    }

    let filterValues = [];
    this.state.activeCheckboxFilters.forEach((checkboxFilterName) => {
      filterValues.push(this.props.checkboxFilterOptions.find((filterOption) => filterOption["key"] == checkboxFilterName)["filter"])
    })

    if (filterValues.length > 0) {
      data.filters = filterValues;
    }

    let filterWhereQuery
    if (this.props.filters) {
      filterWhereQuery = gerenateFilterWhereQuery(
        this.props.filters,
        this.state.filterValues,
      )
    }

    if (filterWhereQuery && filterWhereQuery.length > 0) {
      data.where = JSON.stringify(filterWhereQuery)
    }

    return data;
  };

  getPage = (successCallback) => {
    $.ajax({
      type: "GET",
      url: fullURL(this.props.itemsURL),
      dataType: 'json',
      data: this.dataForQuery(),
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  getUpdatedList = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: result,
                     itemsLastRecieved: result.length});
      if (this.props.shouldInfiniteScroll) {
        setTimeout(this.checkVisibilityForInfiniteScroll,100);
      }
    });
  };

  getAdditionalPage = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: (this.state.currentItems || []).concat(result),
                     itemsLastRecieved: result.length,
                     gettingNewPage: false});
      if (this.props.shouldInfiniteScroll) {
        setTimeout(this.checkVisibilityForInfiniteScroll,100);
      }
    });
  };

  checkVisibilityForInfiniteScroll = () => {
    let element = document.getElementById(this.state.infiniteScrollRandomKey)
    if (element) {
      let elementRect = element.getBoundingClientRect();
      let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      let isVisible =  !(elementRect.bottom < 0 || elementRect.top - viewHeight >= 0);

      if (isVisible) {
        this.moreClick()
      }
    }
  };

  handleCreateCategory = () => {
    let data = {};

    // Search
    if (this.state.searchValue && (this.state.searchValue !== "")) {
      data.search = this.state.searchValue;
    }

    // Filters
    let filterValues = [];
    this.state.activeCheckboxFilters.forEach((checkboxFilterName) => {
      filterValues.push(this.props.checkboxFilterOptions.find((filterOption) => filterOption["key"] == checkboxFilterName)["filter"])
    })

    if (filterValues.length > 0) {
      data.filters = filterValues;
    }

    let filterWhereQuery
    if (this.props.filters) {
      filterWhereQuery = gerenateFilterWhereQuery(
        this.props.filters,
        this.state.filterValues,
      )
    }

    if (filterWhereQuery && filterWhereQuery.length > 0) {
      data.where = JSON.stringify(filterWhereQuery)
    }

    let url = fullURL("/categories/new") + "?" + (new URLSearchParams({search_filters: JSON.stringify(data)})).toString();

    return url;
  };

  render() {
    // SEARCH
    let searchBox = null
    if (this.props.search) {
      let searchBarClass = "gen-list-search-bar"
      if (this.props.searchPlaceHolder) {
        searchBarClass = searchBarClass + " gen-list-search-bar-long"
      }

      searchBox = <form key="search" className={searchBarClass} role="search">
        <input
          type="search"
          placeholder={this.props.searchPlaceHolder || "Search..."}
          value={this.state.searchValue}
          onChange={this.handleSearchChange}
          onKeyPress={this.handleSearchKeyPress}
        />
      </form>;
    }

    // SORT
    let sortOptions = null
    if (this.props.sortOptions) {
      sortOptions = <GenDirectionalButtons
        key="sort-options"
        buttons={this.props.sortOptions}
        selectedValue={this.state.sortValue}
        selectedDirection={this.state.sortDirection}
        onUpdate={this.updateSort}
      />
    }

    // Filters
    let filters=[]
    if (this.props.filters) {
      filters = renderFilters(
        this.props.filters,
        this.state.filterValues,
        this.handleFilterChange
      )
    }

    // Filters
    let checkboxFilters = null
    if (this.props.checkboxFilterOptions) {
      checkboxFilters = <div key="checkbox-filters" className="gen-list-checkbox-filters mb-2">
        {this.props.checkboxFilterOptions.map((filter) => {
          return <div key={filter.key}>
            <label>
              <input
                  type="checkbox"
                  value={filter.key}
                  checked={this.state.activeCheckboxFilters.includes(filter.key)}
                  onChange={this.handleCheckboxFilterChange(this, filter.key)}
              />
              {filter.label}
            </label>
          </div>
        }, this)}
      </div>
    }

    let listItems
    let closeFiltersButton
    if (!this.state.currentItems) {
      closeFiltersButton = <GenButton
        name={<GenLoadingSpinner highlighted={true}/>}
        loading={this.state.loading}
        onClick={this.handleToggleMobileFilters.bind(this)}
        highlighted={true}
      />
    } else if (this.state.currentItems.length === 0) {
      listItems = <div key="no-items" className="gen-list-no-items"><h1>No Items</h1></div>
      closeFiltersButton = <GenButton
        name={"No Matches"}
        loading={this.state.loading}
        onClick={this.handleToggleMobileFilters.bind(this)}
        highlighted={true}
        alertColor={true}
      />
    } else {
      listItems = this.state.currentItems.map((item) => {
        return(this.props.itemView(item))
      })

      listItems = <div key="gen-list-items" className={classNames("gen-list-items", {"gen-list-grid": this.props.gridView})}>{listItems}</div>

      closeFiltersButton = <GenButton
        name={"View Matches"}
        loading={this.state.loading}
        onClick={this.handleToggleMobileFilters.bind(this)}
        highlighted={true}
      />
    }

    let activeFilterCount = this.state.activeCheckboxFilters.length +
      Object.values(this.state.filterValues).filter((val) => ![null, undefined].includes(val) && val.length !== 0).length

    let filterCount = ""
    if (activeFilterCount > 0) {
      filterCount = ` (${activeFilterCount} Active)`
    }

    return(
      <div className="gen-sidebar-list">
        {!this.props.hideSidebar &&
          <div className="gen-list-controls">
            <div className="gen-list-title">
              <h1>{this.props.title}</h1>
              {<GenButton
                name={<span><i className="fa fa-filter"></i>{filterCount}</span>}
                highlighted={true}
                loading={this.state.loading}
                onClick={this.handleToggleMobileFilters.bind(this)}
              />}
            </div>

            <div className="gen-list-search">
              {searchBox}
            </div>

            <div className={classNames("gen-list-mobile-controls-panel", "fitlers", {'active-mobile-panel': this.state.showMobileFilters})}>
              <div className="gen-list-mobile-controls-panel-header">
                <h4>Filters</h4>
                {closeFiltersButton}
              </div>
              <div className="gen-list-mobile-controls-panel-body">
                <div className="gen-list-controls-actions">
                  <div className="gen-list-controls-action">
                    {this.props.actions}
                  </div>
                </div>

                <div className="gen-list-sort-2">
                  {sortOptions}
                </div>

                <div className="gen-list-filters">
                  {checkboxFilters}
                  {filters}
                </div>
              </div>
            </div>

            {this.props.canCreateCategory && <GenButton
              className="absolute-create-category"
              key="Create Filtered Category"
              name="Create Filtered Category"
              href={this.handleCreateCategory()}
              disabled={activeFilterCount == 0 && this.state.searchValue == ""}
              highlighted
            />}
          </div>
        }

        <div className="gen-list-list">
          <div className="gen-list-before-list">
            {this.props.beforeListContent}
          </div>

          <div className="gen-list-list">
            {listItems}
            {!this.props.shouldDisableMoreButton &&
              <GenMore
                isSyncing={this.state.currentItems === null || this.state.gettingNewPage}
                itemsPerPage={this.state.itemsPerPage}
                lastPageLength={this.state.itemsLastRecieved}
                readListPage={this.moreClick}
                shouldInfiniteScroll={this.props.shouldInfiniteScroll}
              />
            }
          </div>

          <div className="gen-list-after-list">
            {this.props.afterListContent}
          </div>
        </div>
      </div>
    );
  }
}
