import React from 'react';

export default class GenFilterGroup extends React.Component {
  render() {
    return(
      <div className="gen-list-filter-group">
        <h4>{this.props.title}</h4>
        <div className="gen-list-filter-group-filters">
          {this.props.children}
        </div>
      </div>
    )
  }
}
