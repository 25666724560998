import React from 'react';

import GenList from 'Gen/List'

import UserSummary from 'User/Summary'

export default class UserList extends React.Component {
  render() {
    return(
        <GenList
          itemsURL={"/users"}
          itemView={function(user){
            return(<UserSummary user={user} key={user.id}/>);
          }}
          search={true}
          sortOptions={[
                        {
                          "title": "First Name",
                          "value": "users.first_name"
                        },
                        {
                          "title": "Last Name",
                          "value": "users.last_name"
                        },
                        {
                          "title": "Email",
                          "value": "users.email"
                        },
                      ]}
        />
    );
  }
}
