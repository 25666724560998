import React from 'react';

export default class GenPanelHeader extends React.Component {
  render() {
    return <div className="gen-panel-header">
      <div className="gen-panel-header-title">
        <h1>{this.props.children}</h1>
      </div>
      <div className="gen-panel-header-right-content">
        {this.props.rightContent}
      </div>
    </div>
  }
}
