import React from 'react';

import classNames from 'classnames'

export default class GenListDisplay extends React.Component {
  onItemClick = (item) => {
    const isSelected = this.props.selected.some(sItem => sItem.id === item.id);
    let newSelectedItems = isSelected ? this.props.selected.filter(sItem => sItem.id !== item.id) : this.props.selected.concat([item])
    this.props.setListSelected(newSelectedItems)
  };

  render() {
    const renderedItems = (this.props.listData || []).map(item => {
      const isSelected = this.props.selected.some(sItem => sItem.id === item.id);

      return (
        <div
          key={item.id}
          className={classNames("panel", "gen-list-display-panel", isSelected ? "selected" : "")}
          onClick={() => {this.onItemClick(item)}}
        >
          {this.props.renderItem(item)}
        </div>
      )
    })

    return (
      <div className="gen-list-display">
        {renderedItems}
      </div>
    )
  }
}
