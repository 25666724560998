import React from 'react';

export default class GenModal extends React.Component {
  render() {
    if (this.props.open) {
      return(
        <div className="gen-modal">
          {this.props.children}
        </div>
      );
    } else {
      return(null);
    }
  }
}
