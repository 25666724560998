import React from 'react'

import * as R from 'ramda'

import GenButton from 'Gen/Button'
import GenPostButton from 'Gen/PostButton'

import {
  inlineHTMLToJSON,
  inlineJSONToHTML
} from 'src/convert'

import {
  DISPLAY_MODES,
  displayModeDisplayName,
} from 'src/display_mode'

import {
  generateNode,
  insertAfterNodeByUIDAtSectionLevel,
  setNodeValueByUIDAndKey,
  modifyNodeValueByUIDAndKey,
  replaceNodeByUID,
  replaceNodeWithArrayByUID,
} from 'src/doc_helpers'

import {
  setStyleArg
} from 'src/inline_json'

import {
  generateStyleFunction
} from 'src/style'

import { fullURL } from 'src/url'

function isCurrentlyEditingText(currentlyEditing) {
  return currentlyEditing?.uid && currentlyEditing?.range && currentlyEditing?.key
}

export default class DocTopBar extends React.Component {
  onListButtonClickGenerator(listType) {
    const {
      editState,
      currentVersion,
      currentDocument,
    } = this.props

    return () => {
      let node = editState.currentlyEditingNode

      let newNode = generateNode(listType)
      let newListItem = newNode.content[0]

      const newRangeSelection = editState.currentlyEditing?.range || {start: 0, end: 0}

      switch (node.type) {
        case "listItem":
          let parentList = R.findLast(node => (R.includes(node.type, ["regularList", "numberedList"])), editState.currentlyEditingAncestors)

          if (parentList.type === listType) {
            const newParagraph = generateNode('paragraph', {content: node.content})

            editState.modifyEditJSON(json => {
              let replaceWithArray = []

              const indexOfNode = parentList.content.findIndex(childNode => childNode.uid === node.uid)
              const numberOfSiblings = parentList.content.length

              if (indexOfNode > 0) {
                const newFirstList = R.over(R.lensProp('content'), R.slice(0, indexOfNode), parentList)
                replaceWithArray = R.append(newFirstList, replaceWithArray)
              }

              replaceWithArray = R.append(newParagraph, replaceWithArray)

              if (numberOfSiblings > indexOfNode + 1) {
                const newSecondList = generateNode(listType, {content: R.slice(indexOfNode + 1, Infinity, parentList.content)})
                replaceWithArray = R.append(newSecondList, replaceWithArray)
              }

              return replaceNodeWithArrayByUID(json, parentList.uid, replaceWithArray)
            })

            editState.setCurrentlyEditing({uid: newParagraph.uid, range: newRangeSelection, key: 'content'})
          } else {
            editState.modifyEditJSON(json => setNodeValueByUIDAndKey(json, parentList.uid, 'type', listType))
          }
          break;
        case "paragraph":
          newListItem.content = node.content

          editState.modifyEditJSON(json => replaceNodeByUID(json, editState.currentlyEditing.uid, newNode))
          editState.setCurrentlyEditing({uid: newListItem.uid, range: newRangeSelection, key: 'content'})
          break;
        default:
          editState.modifyEditJSON(json => insertAfterNodeByUIDAtSectionLevel(json, editState.currentlyEditing.uid, newNode))
          editState.setCurrentlyEditing({uid: newListItem.uid, range: newRangeSelection, key: 'content'})
      }
    }
  }

  onMediaButtonClickGenerate(mediaType) {
    const {
      editState,
      currentDocument,
    } = this.props

    return () => {
      const newKey = {
        image: currentDocument?.images?.[0],
        audio: currentDocument?.audios?.[0],
        video: currentDocument?.media_assets?.media_assets?.[0]?.media_key,
      }[mediaType]

      const newNodeArgs = {
        image: {uri: newKey},
        audio: {uri: newKey, source: 'local'},
        video: {uri: newKey, soure: 'reeldx'},
      }[mediaType]

      const newNode = generateNode(mediaType, newNodeArgs)

      editState.modifyEditJSON(json => insertAfterNodeByUIDAtSectionLevel(json, editState.currentlyEditing.uid, newNode))
      editState.setCurrentlyEditing({uid: newNode.uid, menu: 'mediaMenu', mediaType: mediaType})
    }
  }

  render() {
    const {
      editState,
      currentVersion,
      currentDocument,
      displayState,
    } = this.props

    if (editState.isEditor) {
      let styleButtons = [
        ['bold', 'b'],
        ['italic', 'i'],
        ['underline', 'u'],
        ['subscript', 'sub'],
        ['superscript', 'sup'],
        ['link', 'a']
      ].map(([styleName, tagName]) => {
        let activeStyle = editState.currentlyActiveStyles?.find(style => style.type === tagName)
        let newlyActive = editState.currentlyEditing?.['newlyActiveStyles']?.find(style => style.type === tagName)
        let newlyInactive = editState.currentlyEditing?.['newlyInactiveStyles']?.find(style => style.type === tagName)

        let extraControls
        if ((activeStyle || newlyActive) && !newlyInactive) {
          if (styleName === 'link') {
            let handleChange, linkValue
            if (activeStyle) {
              // Update Current Style
              linkValue = activeStyle.args?.href || ""
              handleChange = (event) => {
                let newLinkValue = event.target.value
                editState.modifyEditJSON(json => {
                  return modifyNodeValueByUIDAndKey(json, editState.currentlyEditing.uid, editState.currentlyEditing.key, inlineHTML => {
                    let newInlineJSON = setStyleArg(inlineHTMLToJSON(inlineHTML), tagName, editState.currentlyEditing.range.start, 'href', newLinkValue)
                    return inlineJSONToHTML(newInlineJSON)
                  })
                })
              }
            } else {
              // Update newlyActiveStyles
              linkValue = newlyActive.args?.href || ""
              handleChange = (event) => {
                let newLinkValue = event.target.value
                editState.setCurrentlyEditing({...editState.currentlyEditing,
                  newlyActiveStyles: editState.currentlyEditing.newlyActiveStyles.map(style => style.type === 'a' ? R.assoc('args', {href: newLinkValue}, style) : style)
                })
              }
            }

            extraControls = <input
              value={linkValue}
              onChange={handleChange}
              style={{width: '200px'}}
              type='text'
              onFocus={(e) => editState.setCurrentlyEditing({...editState.currentlyEditing,
                selectionMenu: 'link'
              })}
              onBlur={(e) => editState.setCurrentlyEditing({...editState.currentlyEditing,
                selectionMenu: undefined
              })}
            />
          }
        }

        return (
          <React.Fragment key={styleName}>
            <GenButton
              spanClasses={ (activeStyle || newlyActive) && !newlyInactive ? 'bg-sky-600' : 'bg-white' }
              key={styleName}
              highlighted={(activeStyle || newlyActive) && !newlyInactive}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              name={<i className={`fa fa-${styleName}`}></i>}
              onClick={generateStyleFunction(tagName, editState)}
            />
            {extraControls}
          </React.Fragment>
        )
      })

      return (
        <div className="doc-top-bar">
          <div>
            <GenButton
              name={<i className="fa fa-save"></i>}
              highlighted
              disabled={!editState.hasUnsavedChanges}
              onClick={editState.save}
            />
            {displayState.documentType !== "Activity" && (
              <GenPostButton
                name="Publish"
                highlighted
                disabled={editState.hasUnsavedChanges}
                url={fullURL(`/documents/${currentDocument.id}/publish`)}
                data={{}}
              />
            )}
            <div className="doc-top-bar-divider"/>
            <select className="my-1 doc-mode-selection" value={displayState.displayMode} onChange={(e) => displayState.setDisplayMode(e.target.value)}>
              {Object.values(DISPLAY_MODES).map(displayMode => <option key={displayMode} value={displayMode}>{displayModeDisplayName(displayMode)}</option>)}
            </select>
            <div className="doc-top-bar-divider"/>
            <GenButton
              spanClasses="bg-white"
              name={<i className="fa fa-undo"></i>}
              disabled={editState.undoQueue.length === 0}
              onClick={editState.undo}
            />
            <GenButton
              spanClasses="bg-white"
              name={<i className="fa fa-repeat"></i>}
              disabled={editState.redoQueue.length === 0}
              onClick={editState.redo}
            />
            <div className="doc-top-bar-divider"/>
            <GenButton
              spanClasses={editState.currentlyEditingNode?.type === 'listItem' ? 'bg-sky-600' : 'bg-white'}
              name={<i className="fa fa-list-ul"></i>}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              highlighted={editState.currentlyEditingNode?.type === 'listItem'}
              onClick={this.onListButtonClickGenerator('regularList')}
            />
            <GenButton
              spanClasses={editState.currentlyEditingNode?.type === 'numberedList' ? 'bg-sky-600' : 'bg-white'}
              name={<i className="fa fa-list-ol"></i>}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              highlighted={editState.currentlyEditingNode?.type === 'numberedList'}
              onClick={this.onListButtonClickGenerator('numberedList')}
            />
            <GenButton
              spanClasses={editState.currentlyEditingNode?.type === 'image' ? 'bg-sky-600' : 'bg-white'}
              name={<i className="fa fa-image"></i>}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              highlighted={editState.currentlyEditingNode?.type === 'image'}
              onClick={this.onMediaButtonClickGenerate('image')}
            />
            <GenButton
              spanClasses={editState.currentlyEditingNode?.type === 'audio' ? 'bg-sky-600' : 'bg-white'}
              name={<i className="fa fa-music"></i>}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              highlighted={editState.currentlyEditingNode?.type === 'audio'}
              onClick={this.onMediaButtonClickGenerate('audio')}
            />
            <GenButton
              spanClasses={editState.currentlyEditingNode?.type === 'video' ? 'bg-sky-600' : 'bg-white'}
              name={<i className="fa fa-video-camera"></i>}
              disabled={!isCurrentlyEditingText(editState.currentlyEditing)}
              highlighted={editState.currentlyEditingNode?.type === 'video'}
              onClick={this.onMediaButtonClickGenerate('video')}
            />
            <div className="doc-top-bar-divider"/>
            {styleButtons}
          </div>
          <div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
