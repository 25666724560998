import React from 'react';

import GenLoadingSpinner from 'Gen/LoadingSpinner'

export default class GenPostButton extends React.Component {
  static defaultProps = {
    highlighted: false,
    disabled: false,
    loading: false
  };

  // name : HTML
  // highlighted : Bool
  // disabled : Bool
  // onClick : f()

  onClick = (value) => {
    if (this.props.disabled === false) {
      this.props.onClick(value);
      value.stopPropagation();
    }
  };

  render() {
    let classes = "gen-button";

    if (this.props.disabled) {
      classes += " disabled";
    }

    if (this.props.highlighted) {
      classes += " highlighted";
    }

    if (this.props.small) {
      classes += " small";
    }

    if (this.props.loading) {
      return(
        <div className={classes} onClick={this.onClick}>
          <span className="button-name"><GenLoadingSpinner/></span>
        </div>
      );
    } else {
      return(
        <div className={classes}>
          <form action={this.props.url} method='POST'>
            <input type='hidden' name='authenticity_token' value={document.head.querySelector("meta[name='csrf-token']").content} />
            {Object.keys(this.props.data).map((key, index) => {
              return <input type='hidden' key={key} name={key} value={JSON.stringify(this.props.data[key])} />
            })}
            <input key='submit' type='submit' value={this.props.name} />
          </form>
        </div>
      )
    }
  }
}
