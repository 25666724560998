import React from 'react';

import GenList from 'Gen/List'

import AddToSummary from 'DocumentSetDocumentRelation/AddTo/Summary'

export default class AddToList extends React.Component {
  render() {
    const {
      rootDocumentSetId,
      documentId,
      showsOwnershipCheckbox,
      userId,
      canAddToUnownedDocumentSets,
      categoryId
    } = this.props

    return (<GenList
      itemsURL={"/document_sets/" + rootDocumentSetId + "/document_sets"}
      itemView={(documentSet) => {
        let locked = documentSet.locked
        let isOwner = documentSet.owner_id && documentSet.owner_id == userId
        let enabled = !locked || isOwner || canAddToUnownedDocumentSets

        return(
          <AddToSummary
            enabled={enabled}
            key={documentSet.id}
            documentSet={documentSet}
            documentId={documentId}
            includesDoc={documentSet.nested_document_ids.includes(documentId)}
            categoryId={categoryId}
          />
        );
      }}
      search
      sortOptions={[
                    {
                      "title": "Name",
                      "value": "document_sets.name"
                    },
                    {
                      "title": "Owner",
                      "value": "users.last_name"
                    }
                  ]}
      checkboxFilterOptions={showsOwnershipCheckbox && [
                              {
                                "key": "owner",
                                "whereFilter": ["=", "document_sets.owner_id", userId],
                                "label": "Only courses that I own"
                              }
                            ]}
    />);
  }
}
