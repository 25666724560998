import React from 'react';

import GenCycleButton from 'Gen/CycleButton'

export default class GenToggleButton extends React.Component {
  static defaultProps = {
    disabled: false
  };

  // name:
  // value : String
  // disabled : Bool
  // onClick (Value ->)

  render() {
    return(
      <GenCycleButton
        states={[
          { "button" : {
              "name" : this.props.name,
              "highlighted" : false,
              "disabled" : false
            },
            "value" : false,
            "nextValue" : true
          },
          { "button" : {
              "name" : (this.props.onName || this.props.name),
              "highlighted" : true,
              "disabled" : false
            },
            "value" : true,
            "nextValue" : false
          }
        ]}
        disabled={this.props.disabled}
        currentValue={this.props.value}
        onClick={this.props.onClick} />
    );
  }
}
