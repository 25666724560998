import React from 'react';

import GenToggleButton from 'Gen/ToggleButton'

export default class GenToggleBar extends React.Component {
  static defaultProps = {
    disabled: false
  };

  // buttons: [{name: , value: , disabled: }]
  // value:
  // disabled : Bool
  // onClick (Value ->)

  onClickFunction = (value) => {
    return( function() {
      this.props.onClick(value)
    }.bind(this))
  };

  render() {
    let toggleButtons = this.props.buttons.map((button) => {
      return(
        <GenToggleButton
          key={button.name}
          name={button.name}
          value={button.value === this.props.value}
          disabled={this.props.disabled || button.disabled}
          onClick={this.onClickFunction(button.value)} />
      );
    });

    return(
      <div className="gen-toggle-bar">
        {toggleButtons}
      </div>
    );
  }
}
