import React from 'react'

import ReactS3Uploader from 'react-s3-uploader'

import classNames from 'classnames'

import axios from 'axios'

const acceptedTypesByMediaType = {
  "image": "image/*",
  "audio": "audio/*",
  "video": "video/mp4,video/*"
}

export default class GenMediaUploadButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onUploadError = this.onUploadError.bind(this)
    this.onUploadFinish = this.onUploadFinish.bind(this)
  }

  onUploadProgress(progressEvent) {
    this.setState({progressPercent: progressEvent})
  }

  onUploadError(error) {
    const {
      onError
    } = this.props

    console.log("ERROR:", error)
    alert("Error Uploading")

    if (onError) { onError(error) }
    this.setState({progressPercent: null})
  }

  onUploadFinish(response) {
    const {
      documentId,
      mediaType,
      onError,
      onSuccess,
      mediaOptions,
    } = this.props

    console.log("FINISH:", response)

    let fileUrl = new URL(response.signedUrl)
    let originalPath = fileUrl.pathname

    console.log("File Path:", originalPath)

    const csrfToken = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

    axios.post(`/documents/${documentId}/media_assets`, {
      media_asset: {
        original_upload_path: originalPath,
        media_type: mediaType,
        ...mediaOptions
      },
    })
    .then((response) => {
      if (onSuccess) { onSuccess() }
      this.setState({progressPercent: null})
    })
    .catch((error) => {
      console.log("ERROR:", error)
      alert("Error Uploading")

      if (onError) { onError(error) }
      this.setState({progressPercent: null})
    })
  }

  render() {
    const {
      mediaType,
      titleEnding=''
    } = this.props

    const hasProgress = typeof(this.state.progressPercent) === 'number'
    const uploadID = `upload_${mediaType}${titleEnding}`

    return <span className="doc-media-upload-button">
      <label htmlFor={uploadID} className={classNames('bg-sky-600 hover:bg-sky-500 p-2 button', {disabled: hasProgress})}>
        {hasProgress ? 'Uploading' : `Upload New ${titleEnding}`}
        <ReactS3Uploader
          signingUrl={`/media_assets/signed_url`}
          signingUrlMethod="GET"
          accept={acceptedTypesByMediaType[mediaType]}
          s3path="/uploads/"
          onProgress={this.onUploadProgress}
          onError={this.onUploadError}
          onFinish={this.onUploadFinish}
          id={uploadID}
          style={{display: 'none'}}
          uploadRequestHeaders={{}}
        />
      </label>
      {hasProgress && <div className="progress progress-striped active">
        <div className="bar" style={{width: `${this.state.progressPercent * 0.95}%`}}></div>
      </div>}
    </span>
  }
}
