import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocListItem from 'Doc/ListItem'

export default class DocNumberedList extends React.Component {
  render() {
    let listItems = this.props.content.map(listItem => {
      return <DocListItem
        key={listItem.uid}
        {...listItem}
        currentDocument={this.props.currentDocument}
        currentVersion={this.props.currentVersion}
        currentUser={this.props.currentUser}
        displayState={this.props.displayState}
        editState={this.props.editState}
      />
    });

    return (
      <ol className="doc-numbered-list">
        {listItems}
        <DocBookmark {...this.props} />
      </ol>
    )
  }
}
