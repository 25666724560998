import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocListItem from 'Doc/ListItem'

export default class DocRegularList extends React.Component {
  render() {
    let listItems = this.props.content.map(listItem => {
      return (
        <DocListItem
          key={listItem.uid}
          {...listItem}
          currentDocument={this.props.currentDocument}
          currentVersion={this.props.currentVersion}
          currentUser={this.props.currentUser}
          displayState={this.props.displayState}
          editState={this.props.editState}
        />
      )
    });

    return (
      <ul className="doc-regular-list">
        {listItems}
        <DocBookmark {...this.props} />
      </ul>
    )
  }
}
