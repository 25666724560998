import React from 'react';

import classNames from 'classnames'

export default class DocBookmark extends React.Component {
  onClick = (e) => {
    this.props.currentUser.addState({[this.props.uid]: {"bookmarked": !this.props.bookmarked}});

    e.stopPropagation();
  };

  render() {
    // DISABLED BOOKMARKS FOR REELDX
    return(null)

    return (
      <div
        className={classNames("doc-bookmark-button", {"bookmarked": this.props.bookmarked})}
        onClick={this.onClick}
      />
    )
  }
}
