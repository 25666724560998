import React from 'react';

import GenButton from 'Gen/Button'
import GenSelectList from 'Gen/SelectList'

export default class DocumentSetUserPermissionSelectList extends React.Component {
  state = {
    selectedDocumentSetUserPermissionIds : [],
    destroyProcessing: false,
    key: 1
  };

  handleDocumentSetUserPermissionSelectionChange = (newSelection) => {
    this.setState({selectedDocumentSetUserPermissionIds : newSelection});
  };

  handleDestroySelectedDocumentSetUserPermissions = () => {
    if (this.state.selectedDocumentSetUserPermissionIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to lend.");
      return(null);
    }

    let data = {documentSetUserPermissionIds: this.state.selectedDocumentSetUserPermissionIds};

    let destroy_url = window.location.href + "/destroy_batch";

    this.setState({destroyProcessing: true});

    $.ajax({
      type: "DELETE",
      url: destroy_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onDestroySelectedDocumentSetUserPermissionsSuccess,
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        this.setState({destroyProcessing: false});
        alert("Error: Removing Members!");
      }
    });
  };

  onDestroySelectedDocumentSetUserPermissionsSuccess = () => {
    this.setState({
      selectedDocumentSetUserPermissionIds : [],
      key: this.state.key + 1,
      destroyProcessing: false
    });
    alert("Members Removed!");
  };

  render() {
    return(
      <GenSelectList
        key={this.state.key}
        selected={this.state.selectedDocumentSetUserPermissionIds}
        onSelectionChange={this.handleDocumentSetUserPermissionSelectionChange}
        selectExtraButtons={<GenButton name="Remove Selected Members" highlighted={true} loading={this.state.destroyProcessing} small={true} disabled={this.state.selectedDocumentSetUserPermissionIds.length === 0} onClick={this.handleDestroySelectedDocumentSetUserPermissions}/>}
        itemView={function(documentSetUserPermission, selected){
          let checkboxClasses = "gen-select-list-select-area"
          if (selected) { checkboxClasses = "bg-sky-600 " + checkboxClasses }
          return(
            <div className="gen-select-list-item" key={documentSetUserPermission.id}>
              <div className={checkboxClasses} />
              <div className="gen-select-list-select-item">
                <div className="type-group-role-summary">
                  <div className="topline">
                    <h1 className="full-name">
                      {documentSetUserPermission.user.first_name + " " + documentSetUserPermission.user.last_name}
                    </h1>
                  </div>

                  <p className="email">{documentSetUserPermission.user.email}</p>
                </div>
              </div>
            </div>);
        }}
        itemsURL={"/document_sets/" + this.props.document_set_id + "/document_set_user_permissions"}
        search={true}
        sortOptions={[
                      {
                        "title": "First Name",
                        "value": "first_name",
                      },
                      {
                        "title": "Last Name",
                        "value": "last_name"
                      },
                      {
                        "title": "Email",
                        "value": "email"
                      },
                      // {
                      //   "title": "Role",
                      //   "value": "role"
                      // }
                    ]}
      />
    );
  }
}
