import React from 'react';

export default class DocExtra extends React.Component {
  render() {
    if (!this.props.editState.isEditor) { return(null); }

    switch(this.props.subtype){
      case "error":
        return <p className="doc-extra doc-extra-error" dangerouslySetInnerHTML={{__html: this.props.content}}/>
      case "warning":
        return <p className="doc-extra doc-extra-warning" dangerouslySetInnerHTML={{__html: this.props.content}}/>
      default:
        return <p className="doc-extra doc-extra-unknown" dangerouslySetInnerHTML={{__html: this.props.content}}/>
    }
  }
}
