import React from 'react';

import AddToButton from 'DocumentSetDocumentRelation/AddTo/Button'

import { documentDisplayTitle } from 'src/document_display_title'

export default class DocumentSummary extends React.Component {
  render() {
    let mediaIcons = []

    if(this.props.document.description.indexOf('extra_video') !== -1) {
      mediaIcons.push(<i key="extra_video" className="fa fa-video-camera" style={{'paddingRight': '5px'}}></i>)
    }

    if(this.props.document.description.indexOf('extra_image') !== -1) {
      mediaIcons.push(<i key="extra_image" className="fa fa-image" style={{'paddingRight': '5px'}}></i>)
    }

    if(this.props.document.description.indexOf('extra_audio') !== -1) {
      mediaIcons.push(<i key="extra_audio" className="fa fa-volume-up" style={{'paddingRight': '5px'}}></i>)
    }

    let canAddButton = this.props.rootDocumentSetId &&
      (this.props.currentUser?.is_admin ||
        ["Faculty", "Manager", "ReelDx_Faculty"].includes(this.props.currentRoleDefinition?.name))

    let addToButton = canAddButton && <AddToButton
      rootDocumentSetId={this.props.rootDocumentSetId}
      document={this.props.document}
      currentUser={this.props.currentUser}
      currentRoleDefinition={this.props.currentRoleDefinition}
      categoryId={this.props.categoryId}
    />

    return(
      <div className="cover-wrapper">
        {addToButton}

        <a className="cover summary" href={"/documents/" + this.props.document.id}>
          <img src={this.props.document.cover_image} alt={`Case ${this.props.document.id} Cover Image`}  width="2092" height="1170"/>
          <h4 className="name">{documentDisplayTitle(this.props.document)}</h4>
          {mediaIcons.length > 0 && <h4>{mediaIcons}</h4>}
        </a>
      </div>
    );
  }
}
