import React from 'react'

import * as R from 'ramda'

import GenButton from 'Gen/Button'
import GenModal from 'Gen/Modal'

import {
  deepFindByUID,
  setNodeValueByUIDAndKey,
} from 'src/doc_helpers'

const EDIT_METADATA_LOOKUP_BY_TYPE = {
  openAiChat: [
    {
      key: "aiInstructions",
      inputType: "textarea",
      label: "AI Prompt",
    },
    {
      key: 'assistantDisplayName',
      inputType: "textinput",
      label: "AI Display Name",
    },
    {
      key: 'assistantDisplayImage',
      inputType: "textinput",
      label: "AI Display Image",
      extraExplination: "Use a file name for an image uploaded to the case this activity is used in. If blank then display name will be used.",
    },
    {
      key: 'userDisplayName',
      inputType: "textinput",
      label: "User Display Name",
    },
    {
      key: 'userDisplayImage',
      inputType: "textinput",
      label: "User Display Image",
      extraExplination: "Use a file name for an image uploaded to the case this activity is used in. If blank then display name will be used.",
    },
    {
      key: 'placeholderText',
      inputType: "textinput",
      label: "Placeholder Text",
    },
    {
      key: 'startWithAssistant',
      inputType: "checkbox",
      label: "Start with AI",
    },
  ],
}


export default class DocEditMetadataModal extends React.Component {
  render() {
    const {
      editState,
      mergedJSON,
    } = this.props

    if (!editState.isEditor || !editState.editMetadataUid) {
      return null;
    }

    const editMetadataNode = deepFindByUID(mergedJSON, editState.editMetadataUid)

    let metadataInputs = EDIT_METADATA_LOOKUP_BY_TYPE[editMetadataNode.type].map((keyMetadata) => {
      let inputInput;
      switch (keyMetadata.inputType) {
        case "textarea":
          inputInput = (
            <textarea rows="10" value={editMetadataNode[keyMetadata.key] || ""} onChange={(e) => {
              editState.modifyEditJSON(json => {
                return setNodeValueByUIDAndKey(json, editState.editMetadataUid, keyMetadata.key, e.target.value);
              })}}
            />
          )

          break;
        case "textinput":
          inputInput = (
            <input type="text" value={editMetadataNode[keyMetadata.key] || ""} onChange={(e) => {
              editState.modifyEditJSON(json => {
                return setNodeValueByUIDAndKey(json, editState.editMetadataUid, [keyMetadata.key], !e.target.value || R.isEmpty(e.target.value) ? "" : e.target.value);
              })}}
            />
          )

          break;
        case "checkbox":
          inputInput = (
            <input type="checkbox" checked={!!editMetadataNode.startWithAssistant} onChange={(e) => {
              editState.modifyEditJSON(json => {
                return setNodeValueByUIDAndKey(json, editState.editMetadataUid, 'startWithAssistant', e.target.checked);
              })}}
            />
          )

          break;
        default:
          break;
      }

      return (
        <div key={keyMetadata.key}>
          <label>{keyMetadata.label || keyMetadata.key}:</label>
          {keyMetadata.extraExplination && <p>{keyMetadata.extraExplination}</p>}
          {inputInput}
        </div>
      );
    })

    return (
      <GenModal open>
        <div className="gen-modal-panel">
          <h1>Settings for {editMetadataNode.type}</h1>

          <div>
            <label>UID: {editMetadataNode.uid}</label>
          </div>

          {metadataInputs}

          <GenButton
            name="Close"
            highlighted
            onClick={() => editState.setEditMetadata(null)}
          />
        </div>
      </GenModal>
    )
  }
}
