import React from 'react';

import GenList from 'Gen/List'

import DocumentUserSummary from 'DocumentUser/Summary'

export default class DocumentUserList extends React.Component {
  render() {
    return(
      <GenList
        itemView={documentUser => <DocumentUserSummary key={documentUser.id} documentUser={documentUser}/>}
        itemsURL={`/documents/${this.props.documentId}/document_users`}
        search
      />
    );
  }
}
