import React from 'react';

import GenLoadingSpinner from 'Gen/LoadingSpinner'

export default class GenButton extends React.Component {
  static defaultProps = {
    highlighted: false,
    disabled: false,
    loading: false,
    top_and_bottom_margins: false,
  };

  // name : HTML
  // highlighted : Bool
  // disabled : Bool
  // onClick : f()

  onClick = (value) => {
    if (this.props.disabled === false) {
      if (this.props.confirmMessage && !confirm(this.props.confirmMessage)) {
        return
      }

      if (this.props.href) {
        if (this.props.target === "_blank") {
          window.open(this.props.href, "_blank");
        } else {
          Turbolinks.visit(this.props.href)
        }
      } else {
        this.props.onClick(value);
      }
      value.stopPropagation();
    }
  };

  render() {
    let classes = "gen-button";
    let spanClasses = "bg-sky-600 border border-sky-600 button-name"

    if (this.props.spanClasses) {
      spanClasses = `${this.props.spanClasses} ` + spanClasses;
    }

    if (this.props.disabled) {
      classes += " disabled cursor-not-allowed";
    }

    if (this.props.highlighted) {
      classes += " highlighted";

      if (!this.props.disabled) {
        spanClasses += " hover:bg-sky-500"
      }
    }

    if (this.props.destructive) {
      classes += " destructive"
    }

    if (this.props.small) {
      classes += " small";
    }

    if (this.props.alertColor) {
      classes += " alertColor";
    }

    if (this.props.bottom_margin) {
      classes += " bottom_margin";
    }

    if (this.props.className) {
      classes += ` ${this.props.className}`;
    }

    return(
      <div className={classes} onClick={this.onClick}>
        <span className={spanClasses}>{this.props.loading ? <GenLoadingSpinner/> : this.props.name}</span>
      </div>
    );
  }
}
