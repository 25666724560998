import React from 'react';

import connectToList from 'store/list'

import GenMore from 'Gen/More'
import GenControlsBar from 'Gen/ControlsBar'
import GenSearchBox from 'Gen/SearchBox'
import GenSortButtons from 'Gen/SortButtons'

class InstitutionContributorList extends React.Component {
  componentDidMount() {
    const endpoint = this.props.institution_id ? `institutions/${this.props.institution_id}/institution_contributors` : `contributors/${this.props.contributor_id}/institution_contributors`

    this.props.institution_contributors.mountList(endpoint)
  }

  render() {
    let items = (this.props.institution_contributors.listData || []).map(institution_contributors => {
      if (this.props.institution_id) {
        const contributor = institution_contributors.contributor
        return (
          <div className="panel" key={institution_contributors.id}>
            <div className="gen-panel-header">
              <div className="gen-panel-header-title">
                <h1>
                  <a href={"/contributors/" + contributor.id}>{contributor.name}{contributor.designation && `, ${contributor.designation}`}</a>
                </h1>
              </div>

              <div className="gen-panel-header-right-content">
                <div className="list-stat-panels">
                  <div className="list-stat-panel">
                    <h1>{contributor.cached_assignment_count.toLocaleString()}</h1>
                    <h5>Assignments</h5>
                  </div>
                  <div className="list-stat-panel">
                    <h1>{contributor.cached_view_count.toLocaleString()}</h1>
                    <h5>Views</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className="panel" key={institution_contributors.id}>
            <h1>
              <a href={`/institutions/${institution_contributors.institution.id}`}>{institution_contributors.institution.name}</a>
            </h1>
          </div>
        )
      }
    })

    let extraSortOptions = []
    if (this.props.institution_id) {
      extraSortOptions = [
        {
          "title": "Assignments",
          "value": "contributors.cached_assignment_count"
        },
        {
          "title": "Views",
          "value": "contributors.cached_view_count"
        }
      ]
    }

    return (
      <div>
        <GenControlsBar>
          <GenSearchBox
            {...this.props.institution_contributors}
          />
          <GenSortButtons
            {...this.props.institution_contributors}
            sortOptions={[
              {
                "title": "Name",
                "value": this.props.institution_id ? "contributors.name" : "institutions.name"
              },
              ...extraSortOptions
            ]}
          />
        </GenControlsBar>
        {items}
        <GenMore
          {...this.props.institution_contributors}
          shouldInfiniteScroll
        />
      </div>
    )
  }
}

export default connectToList(InstitutionContributorList, 'institution_contributors')
