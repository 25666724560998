import React from 'react';

export default class GenBreadcrumbs extends React.Component {
  render() {
    var breadcrumbs = this.props.breadcrumbs.map((breadcrumb) => {
      return (<a key={breadcrumb[1]} onClick={this.props.callbacks.openSection.bind(null, breadcrumb[1])} dangerouslySetInnerHTML={{__html: breadcrumb[0]}}/>);
    });

    return(
      <div className="gen-breadcrumbs">
        {breadcrumbs}
      </div>
    );
  }
}
