import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocExtra from 'Doc/Extra'

import DocInlineText from 'Doc/InlineText'

export default class DocAudio extends React.Component {
  audioURL() {
    return(
      this.props.currentVersion.aws_folder_url +
      "/original/audios/" +
      this.props.uri
    );
  };

  render() {
    let title = <figcaption>
      <DocInlineText content={this.props.title} uid={this.props.uid} contentKey="title" editState={this.props.editState}/>
    </figcaption>;

    switch (this.props.source) {
      case "local":
        return (
          <div className="doc-audio doc-audio-local">
            <audio preload="none" controls>
              <source src={this.audioURL()} />
            </audio>
            {title}
            <DocBookmark {...this.props} />
          </div>
        )
      case "soundcloud":
        return (
          <div className="doc-audio doc-audio-soundcloud">
            <iframe width="100%" height="450" scrolling="no" frameBorder="no" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + this.props.uri + "&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true"}></iframe>
            {title}
            <DocBookmark {...this.props} />
          </div>
        )
      default:
        return (
          <DocExtra
            subtype="error"
            content={"Unknown audio source type: " + this.props.source}
          />
        )
    }
  }
}
