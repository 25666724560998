import React, {useState} from 'react';
import useWindowSize from "hooks/useWindowSize";

import DocumentSummary from 'Document/Summary'

import classNames from 'classnames';

export default function CategorySummary(props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const screenWidth = useWindowSize().width;

  const handleSeeAllClicked = () => {
    setIsExpanded(!isExpanded)

    // Follow-through update event to Amity
    let data = {
      userId: props.currentUser.id,
      categoryId: props.id,
      event: "category_uncollapsed",
      value: !isExpanded
    };

    let url = window.location.href + `/${props.id}/record_events_amity`;

    $.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: (() => {}),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  }

  const handleCategoryClicked = () => {
    let data = {
      userId: props.currentUser.id,
      categoryId: props.id,
      event: "category_entered"
    };

    let url = window.location.href + `/${props.id}/record_events_amity`;

    $.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: (() => {}),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  }

  const handleMoveCategory = (direction) => {
    let data = {
      direction: direction,
      userId: props.currentUser.id
    };

    let url = window.location.href;
    if (!props.favorited_by_user) {
      url += `/${props.id}/move_category`;
    } else {
      url = url.replace("categories", "category_users");
      url += `/${props.category_user_id}/move_category`;
    }

    $.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: (props.positionCallback),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  }

  const handleFavoriteCategory = () => {
    let data = {
      userId: props.currentUser.id
    };

    let url = window.location.href + `/${props.id}/favorite_category`;

    $.ajax({
      type: "POST",
      url: url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: (props.positionCallback),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  }

  if (props.hidden && !props.currentUser.is_admin) {
    return null;
  }

  let title = props.name == "Randomizer" ? <h3> {props.name} </h3> : <a href={"/categories/" + props.id} onClick={handleCategoryClicked}> <h3> {props.name} </h3> </a>;

  let randomizerButton = props.name == "Randomizer" &&
  <button title="Shuffle" className="button bg-sky-600 hover:bg-sky-500 category-control" onClick={() => props.itemRefreshCallback(props.id)}>
    <i className="fa fa-random"></i>
  </button>

  let favoriteButtonClass = 'button category-control'
  if (props.currentUser.is_admin) {
    favoriteButtonClass += ' disabled'
  } else {
    favoriteButtonClass += ` ${props.favorited_by_user ? 'favorited' : ''}`
  }

  let favoriteButton = <button title="Favorite" className={favoriteButtonClass} onClick={() => handleFavoriteCategory()} disabled={props.currentUser.is_admin}>
    <i className="fa fa-star"></i>
  </button>

  let expandButton = <button title={isExpanded ? "Hide" : `See All ${props.documents.length}`} className="button category-control bg-sky-600 hover:bg-sky-500" onClick={() => handleSeeAllClicked()}>
    <i className={`fa fa-${isExpanded ? 'compress' : 'expand'}`}></i>
  </button>

  let moveUpButtonClass = "button category-control"
  if (props.top_of_list) {
    moveUpButtonClass += ' disabled'
  } else {
    moveUpButtonClass += ' bg-sky-600 hover:bg-sky-500'
  }
  let moveUpButton = <button title="Move up" className={moveUpButtonClass} onClick={() => handleMoveCategory("higher")} disabled={props.top_of_list}>
    <i className="fa fa-arrow-up"></i>
  </button>

  let moveDownButtonClass = "button category-control"
  if (props.bottom_of_list) {
    moveDownButtonClass += ' disabled'
  } else {
    moveDownButtonClass += ' bg-sky-600 hover:bg-sky-500'
  }
  let moveDownButton = <button title="Move down" className={moveDownButtonClass} onClick={() => handleMoveCategory("lower")} disabled={props.bottom_of_list}>
    <i className="fa fa-arrow-down"></i>
  </button>

  let canAddPositionControls = (props.currentUser.is_admin || (!props.currentUser.is_admin && props.favorited_by_user))
  let categoryControlsContainer = <div className="category-control-container">
    {expandButton}
    {randomizerButton}
    {favoriteButton}
    {canAddPositionControls && moveUpButton}
    {canAddPositionControls && moveDownButton}
  </div>

  let documents;
  if (props.documents && props.documents.length > 0) {
    let documentsToRender = props.documents
    if (!isExpanded) {
      documentsToRender = documentsToRender.slice(0, 3)
    }

    documents = (
      <div
        id={`category-list-${props.id}`}
        className={classNames("category-container-covers", {"expanded": isExpanded})}
      >
        {documentsToRender.map(doc => {
          return <DocumentSummary
            document={doc}
            key={doc.id}
            rootDocumentSetId={props.rootDocumentSetId}
            currentUser={props.currentUser}
            currentRoleDefinition={props.currentRoleDefinition}
            categoryId={props.id}
          />;
        })}
      </div>
    )
  } else {
    documents = <p> This category has no cases to display yet. </p>;
  }

  return <div className={`category-container ${props.hidden && "hidden-category-panel"}`}>
    <div className="category-container-covers-container">
      <div className="category-container-header" style={{'marginBottom': 15}}>
          {title}
      </div>

      {documents}
    </div>

    {categoryControlsContainer}
  </div>
}
