import React from 'react';

import GenButton from 'Gen/Button'
import GenMore from 'Gen/More'

import { fullURL } from 'src/url'

function filterKeys(object, keys) {
    var result = {};
    for (var key in object)
        if (keys.indexOf(key) > -1)
            result[key] = object[key];
    return result;
}

export default class GenTable extends React.Component {
  // React.PropTypes
  // propTypes: {
  //   // ids:
  //   // notIds:
  //   // itemsURL:
  //   // search:
  //   // sortOptions:
  //   // filterOptions:
  // },
  //
  static defaultProps = {
    historyPersistance: false,
    defaultItemsPerPage: 12,
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      currentItems: null,
      itemsPerPage: props.defaultItemsPerPage,
      pageNumber: 0,
      gettingNewPage: false,
      itemsLastRecieved: null,
      searchValue: "",
      searchTimer: null,
      sortValue: null,
      sortDirection: null,
      activeFilters: {},
      activeWhereFilters: {}
    };

    const history = this.getHistoryState();

    this.state = {
      ...defaults,
      ...history
    };
  }

  componentDidMount() {
    this.getUpdatedList();
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.searchTimer);
    }
  }

  // search=string
  // page=int
  // sort_value=string
  // sort_order=string
  //
  getHistoryState = () => {
    let state = history.state;
    if (state === null) {
      state = {};
    }
    return(filterKeys(state, ["searchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeWhereFilters"]));
  };

  setHistoryState = () => {
    const currentState = history.state;
    const newPartial = filterKeys(this.state, ["searchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeWhereFilters"]);
    const newState = {...currentState, ...newPartial};

    history.replaceState(newState, document.title);
  };

  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value,
                   currentItems: null,
                   gettingNewPage: false,
                   pageNumber: 0});

    if (this.state.searchTimer) { clearTimeout(this.state.searchTimer); }
    this.setState({searchTimer: setTimeout(this.updateSearchWithTimer, 1000)});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSearchWithTimer = () => {
    // this.updateUSC();
    this.getUpdatedList();
    this.setState({timer: null});
  };

  updateSort = (newSortValue) => {
    let newSortDirection = 'ascending'
    if (newSortValue === this.state.sortValue && this.state.sortDirection === 'ascending') {
      newSortDirection = 'descending'
    }

    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection,
                    currentItems: null,
                    gettingNewPage: false,
                    pageNumber: 0 }, this.getUpdatedList);
  };

  handleFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveFilters = that.state.activeFilters;
      let newActiveFilters = {};
      for (var attr in currentActiveFilters) {
        if (attr != newKey) {
          newActiveFilters[attr] = currentActiveFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveFilters[newKey] = newValue;
      }

      that.setState({ activeFilters: newActiveFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  handleWhereFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveWhereFilters = that.state.activeWhereFilters;
      let newActiveWhereFilters = {};
      for (var attr in currentActiveWhereFilters) {
        if (attr != newKey) {
          newActiveWhereFilters[attr] = currentActiveWhereFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveWhereFilters[newKey] = newValue;
      }

      that.setState({ activeWhereFilters: newActiveWhereFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  moreClick = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1,
                    gettingNewPage: true}, this.getAdditionalPage);
  };

  dataForQuery = () => {
    let data = {};

    if (this.props.ids) {
      data.ids = this.props.ids;
    }

    if (this.props.notIds) {
      data.not_ids = this.props.notIds;
    }

    if (this.state.searchValue && this.state.searchValue.trim() !== "") {
      data.search = this.state.searchValue;
    }

    if (this.state.sortValue) {
      data.sort_order = (this.state.sortDirection === "descending") ? "DESC" : "ASC";
      data.sort_by = this.state.sortValue;
    }


    if (this.state.itemsPerPage) {
      if (this.state.currentItems === null) {
        data.page = 0;
        data.per_page = (this.state.pageNumber + 1) * this.state.itemsPerPage;
      } else {
        data.page = this.state.pageNumber;
        data.per_page = this.state.itemsPerPage;
      }
    }

    let filterValues = [];
    for(var key in this.state.activeFilters) {
      filterValues.push(this.state.activeFilters[key]);
    }
    if (filterValues.length > 0) {
      data.filters = filterValues;
    }

    let whereValues = [];
    for(var key in this.state.activeWhereFilters) {
      let whereFilterOption = this.props.whereFilterOptions.find((wfo) => {return(wfo.title === key)})
      whereValues.push(whereFilterOption["options"][this.state.activeWhereFilters[key]]);
    }
    if (whereValues.length > 0) {
      data.where = JSON.stringify(whereValues);
    }

    return data;
  };

  getPage = (successCallback) => {
    $.ajax({
      type: "GET",
      url: fullURL(this.props.itemsURL),
      dataType: 'json',
      data: this.dataForQuery(),
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  getUpdatedList = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: result,
                     itemsLastRecieved: result.length});
    });
  };

  getAdditionalPage = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: (this.state.currentItems || []).concat(result),
                     itemsLastRecieved: result.length,
                     gettingNewPage: false});
    });
  };

  onItemClick = (item) => {
    let newSelected
    let selectedKey = this.props.selectedKey || "id"
    if (this.props.selected.indexOf(item[selectedKey]) >= 0) {
      newSelected = this.props.selected.filter(function(id){ return(id !== item[selectedKey]); });
    } else {
      newSelected = this.props.selected.concat([item[selectedKey]]);
    }

    this.props.onSelectionChange(newSelected);
  };

  selectAll = (successCallback) => {
    let selectedKey = this.props.selectedKey || "id"
    if (this.state.currentItems) {
      this.props.onSelectionChange(this.state.currentItems.map(function(item) { return(item[selectedKey]); }));
    } else {
      this.props.onSelectionChange([]);
    }
  };

  deselectAll = () => {
    this.props.onSelectionChange([]);
  };

  render() {
    let controlPanel
    if (this.props.search || this.props.filterOptions || this.props.whereFilterOptions) {
      let searchBox = null
      if (this.props.search) {
        searchBox = <form className="gen-list-search-bar" role="search">
          <input type="search" placeholder="Search..." value={this.state.searchValue} onChange={this.handleSearchChange} onKeyPress={this.handleSearchKeyPress} />
        </form>;
      }

      let filterOptions
      if (this.props.filterOptions || this.props.whereFilterOptions) {
        let activeFilters = this.state.activeFilters;
        let activeWhereFilters = this.state.activeWhereFilters;
        filterOptions = <div className="gen-list-filters">
          {this.props.filterOptions && this.props.filterOptions.map(function(filter){
            let filterOut = <div key={filter.title} className="gen-list-filter">
              <h6>{filter.title}</h6>
              <select defaultValue={activeFilters[filter.title] || "All"} onChange={this.handleFilterChange(this, filter.title)}>
                <option value="All">All</option>
                {filter.options.map(function(option){
                  return(
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </div>;

              return( filterOut );
            }, this)}
          {this.props.whereFilterOptions && this.props.whereFilterOptions.map(function(whereFilter){
            let whereFilterOut = <div key={whereFilter.title} className="gen-list-filter">
              <h6>{whereFilter.title}</h6>
              <select defaultValue={activeWhereFilters[whereFilter.title] || "All"} onChange={this.handleWhereFilterChange(this, whereFilter.title)}>
                <option value="All">All</option>
                {Object.keys(whereFilter.options).map(function(option_key){
                  return(
                    <option key={option_key} value={option_key}>
                      {option_key}
                    </option>
                  );
                })}
              </select>
            </div>;

              return( whereFilterOut );
            }, this)}
        </div>;
      }

      controlPanel = <div className="gen-list-control-panel">
        {searchBox}
        {filterOptions}
      </div>;
    }

    let tableHeaderCells = this.props.columns.map((column) => {
      let orderArrow = null
      if (column.sort_column === this.state.sortValue) {
        if (this.state.sortDirection === 'ascending') {
          orderArrow = <span>&#x25BC;</span>
        } else {
          orderArrow = <span>&#x25B2;</span>
        }
      }

      let className = "sort_cell "
      if (column.column_width) {
        className = className + column.column_width + "-column-size"
      }

      return <td key={column.title} onClick={() => {this.updateSort(column.sort_column)}} className={className}>
        {column.title}
        {orderArrow}
      </td>
    })

    let tableHeader = <tr>
      <td key="select-box" className="select-box"></td>
      {tableHeaderCells}
    </tr>

    let itemRows
    if (this.state.currentItems === null) {
      itemRows = [];
    } else if (this.state.currentItems.length === 0) {
      itemRows = [];
    } else {
      itemRows = this.state.currentItems.map((item) => {
        let cells = this.props.columns.map(function(column) {
          let className = ""
          if (column.column_width) {
            className = column.column_width + "-column-size"
          }

          return <td key={column.title} className={className}>{column.calculated_value(item)}</td>
        })

        // Note: tailwind classes added dynamically will not be included in the build, unless it is
        // referenced somewhere else explicitly or added to the safelist in tailwind.config.js
        let classes = ""
        let selectedKey = (this.props.selectedKey) || "id"
        if (this.props.selected.indexOf(item[selectedKey]) >= 0) {
          classes = "bg-sky-100"
        }

        return <tr key={item[selectedKey]} className={classes}>
          <td key="select-box" className="select-box">
            <input
              type="checkbox"
              checked={this.props.selected.indexOf(item[selectedKey]) >= 0}
              onChange={() => {this.onItemClick(item)}} />
          </td>
          {cells}
        </tr>
      });
    }

    return(
      <div className="gen-table">
        <div className="gen-select-list-buttons">
          <div className="gen-select-list-buttons-left flex gap-2 items-baseline">
            <GenButton name={`Select ${this.state.currentItems ? this.state.currentItems.length + " " : ""}Shown`} onClick={this.selectAll} highlighted small/>
            <GenButton name="Deselect All" onClick={this.deselectAll} highlighted small/>
            <span>{this.props.selected.length} selected</span>
          </div>
          <div className="gen-select-list-buttons-right">
            {this.props.selectExtraButtons}
          </div>
        </div>
        {controlPanel}
        <table>
          <thead>
            {tableHeader}
          </thead>
          <tbody>
            {itemRows}
          </tbody>
        </table>
        <GenMore
          isSyncing={this.state.currentItems === null || this.state.gettingNewPage}
          itemsPerPage={this.state.itemsPerPage}
          lastPageLength={this.state.itemsLastRecieved}
          readListPage={this.moreClick}
          shouldInfiniteScroll={this.props.shouldInfiniteScroll}
        />
      </div>
    );
  }
}
