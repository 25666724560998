import React from 'react';

import classNames from 'classnames'

import DocumentSetDocumentRelationScheduleStatus from 'DocumentSetDocumentRelation/ScheduleStatus'

import { documentDisplayTitle } from 'src/document_display_title'

export default class DocumentSetDocumentRelationSummary extends React.Component {
  render() {
    const {
      documentSetDocumentRelation,
      documentSet,
      show_status,
      isLearner,
    } = this.props;

    const {
      document_set_document_activities,
      document_set_document_state,
      document,
    } = documentSetDocumentRelation;

    const activeActivities = document_set_document_activities.filter(
      dsda => !dsda.show_after || new Date() > new Date(dsda.show_after)
    )
    const skillBadge = `${activeActivities.length} ${activeActivities.length > 1 ? 'Activities' : 'Activity'}`
    const reviewedActivities = activeActivities.filter(dsda => Boolean(dsda.reviewed_at))
    const reviewedBadge = `${reviewedActivities.length} Reviewed`

    let gradeBadge;
    if (documentSetDocumentRelation.is_completed) {
      const renderedPercent = `${Math.round(document_set_document_state.grade_percentage * 100)}%`

      if (document_set_document_state.grade_percentage > documentSet.passing_percentage) {
        gradeBadge = <div className="skill-badge bg-green-600"><i className="fa fa-check"></i> {renderedPercent}</div>
      } else if(document_set_document_state.attempt_index + 1 < documentSet.attempt_limit) {
        gradeBadge = <div className="skill-badge bg-red-600"><i className="fa fa-refresh"></i> {renderedPercent}</div>
      } else {
        gradeBadge = <div className="skill-badge bg-red-600"><i className="fa fa-times"></i> {renderedPercent}</div>
      }
    }

    return(
      <a className="cover-wrapper cover summary" href={documentSetDocumentRelation.is_locked ? "#" : `/document_set_document_relations/${documentSetDocumentRelation.id}`}>
        {show_status && <div className="visibility-status-bar">
          <DocumentSetDocumentRelationScheduleStatus
            showAfter={documentSetDocumentRelation.show_after}
            showUntil={documentSetDocumentRelation.show_until}
          />
        </div>}
        <div className="skill-badges flex flex-col items-end gap-2">
          { gradeBadge }
          {
            documentSetDocumentRelation.is_locked &&
            <div className="skill-badge bg-sky-600"><i className="fa fa-lock"></i></div>
          }
          {
            !documentSetDocumentRelation.is_gated && activeActivities.length > 0 &&
            <div className="skill-badge bg-sky-600">{skillBadge}</div>
          }
          {
            !documentSetDocumentRelation.is_gated && isLearner && activeActivities.length > 0 && reviewedActivities.length > 0 &&
            <div className="skill-badge bg-sky-600">{reviewedBadge}</div>
          }
        </div>
        <img className={classNames({"locked-cover": documentSetDocumentRelation.is_locked})} src={document.cover_image} alt={`Case ${document.id} Cover Image`}/>
        <h4 className="name">{documentDisplayTitle(document)}</h4>
      </a>
    );
  }
}
