import React from 'react';

import GenList from 'Gen/List'

import VersionSummary from 'Version/Summary'

export default class VersionList extends React.Component {
  render() {
    return(
      <GenList
        itemView={version => <VersionSummary key={version.id} version={version}/>}
        itemsURL={`/documents/${this.props.documentId}/versions`}
        search
      />
    );
  }
}
