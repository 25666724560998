import React from 'react';

export default class DocumentSetSummary extends React.Component {
  render() {
    const {
      documentSet,
    } = this.props;

    let linkStyle = {}
    if (!!documentSet.catalog_id) {
      linkStyle = {
        color: "#0570c7",
      }
    }

    return(
      <div className="panel">
        <h1 className="name">
          <a href={"/document_sets/" + documentSet.id} style={linkStyle}>
            {documentSet.name}
          </a>
        </h1>
        <p className="description">{documentSet.description}</p>
        {documentSet.owner_full_name && <p> <strong> Course Owner: </strong> {documentSet.owner_full_name}</p>}
      </div>
    );
  }
}

// Badge to add back in later
// {documentSet.badge_url &&
//   <img src={documentSet.badge_url} alt="Course Badge" class="ml-2 inline-block max-h-10"/>
// }