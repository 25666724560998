import React from 'react';

import * as R from 'ramda'

import {
  setNodeValueByUIDAndKey
} from 'src/doc_helpers'

import DocBookmark from 'Doc/Bookmark'
import DocExtra from 'Doc/Extra'

import DocInlineText from 'Doc/InlineText'

import DocVideoJWPlayer from 'Doc/VideoJWPlayer'

import { performActions } from 'src/doc_actions'

export default class DocVideo extends React.Component {
  videoURL = () => {
    return(
      this.props.currentVersion.aws_folder_url +
      "/original/videos/" +
      this.props.uri
    );
  };

  onVideoEditClick = () => {
    if (this.props.editState.isEditing) {
      this.props.editState.setCurrentlyEditing({uid: this.props.uid, menu: 'mediaMenu', mediaType: 'video'})
    }
  }

  handleImagingTypeChange(e) {
    this.props.editState.modifyEditJSON(json =>
      setNodeValueByUIDAndKey(json, this.props.uid, 'imagingType', e.target.value)
    )
  }

  render() {
    let video;
    switch (this.props.source) {
      case "local":
        video = <video preload="none" controls>
          <source src={this.videoURL()} />
        </video>

        break;
      case "youtube":
        video = <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + this.props.uri} frameBorder="0" allowFullScreen></iframe>

        break;
      case "vimeo":
        video = <iframe src={"https://player.vimeo.com/video/" + this.props.uri} width="500" height="281" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>

        break;
      case "reeldx":
        let mediaAsset = this.props.currentDocument.media_assets.find(media_asset => media_asset.media_key === this.props.uri)

        let coverImageURL
        if (this.props.cover) {
          coverImageURL = `${this.props.currentVersion.aws_folder_url}/compressed/images/${this.props.cover}`
        }

        video = <DocVideoJWPlayer
          key={this.props.uri}
          videoURL={`https://d3o9veyy7wy4ai.cloudfront.net/${this.props.uri}`}
          coverImageURL={coverImageURL}
          hasCaptions={!!mediaAsset?.three_play_media_captions_processed_at}
          shouldLoop={!!mediaAsset?.is_silent}
          onVideoPlayEvent={time => this.props.currentUser.logEvent(this.props.uid, "video_played", time)}
          onVideoCompleteEvent={() => this.props.onVideoComplete && performActions(this.props.onVideoComplete, this.props.currentUser)}
        />

        break;
      default:
        return (
          <DocExtra
            subtype="error"
            content={"Unknown video source type: " + this.props.source}
          />
        )
    }

    return (
      <div className={`doc-video doc-video-${this.props.source}`}>
        <div className="doc-video-wrapper">
          {video}
          {this.props.editState.isEditing &&
           <>
             <i className="fa fa-cog bg-sky-600 p-2 doc-media-edit-button" onClick={this.onVideoEditClick.bind(this)}> Edit Video</i>
             {'imagingTypes' in this.props && R.length(this.props.imagingTypes) > 0 &&
              <select value={this.props.imagingType} onChange={this.handleImagingTypeChange.bind(this)} className="tag-select">
                <option value="">Select Imaging Type</option>
                {this.props.imagingTypes.map((t) => <option value={t} key={t}>{t}</option>)}
              </select>
             }
           </>
          }
        </div>
        <figcaption>
          <DocInlineText content={this.props.title} uid={this.props.uid} contentKey="title" editState={this.props.editState}/>
        </figcaption>
        <figcaption>
          {this.props.extraTitle}
        </figcaption>
        <DocBookmark {...this.props} />
      </div>
    )

  }
}
