import React from 'react';

import * as R from 'ramda';

import {
  inlineHTMLToJSON,
  inlineJSONToHTML
} from 'src/convert'

import {
  modifyNodeValueByUIDAndKey
} from 'src/doc_helpers'

import {
  replaceText,
  addStyle,
  removeStyle
} from 'src/inline_json'

import {
  getDocRangeSelection,
  areEqualRangeSelections,
  setDocSelection
} from 'src/selection'

export default class DocInlineText extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()

    this.handleInput = this.handleInput.bind(this)
    this.handlePaste = this.handlePaste.bind(this)
  }

  handleInput() {
    let currentlyEditing = this.props.editState.currentlyEditing
    let afterInputRangeSelection = getDocRangeSelection()

    if (
      currentlyEditing &&
      afterInputRangeSelection &&
      currentlyEditing.uid === afterInputRangeSelection.uid &&
      currentlyEditing.key === afterInputRangeSelection.key &&
      currentlyEditing.range
    ) {
      let oldText = inlineHTMLToJSON(this.props[currentlyEditing.key] || "")
      let newText = inlineHTMLToJSON(this.ref.current.innerHTML).text

      let newTextSpan = newText.slice(currentlyEditing.range.start, afterInputRangeSelection.range.end)

      this.props.editState.modifyEditJSON(json => {
        return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
          let newInlineJSON = replaceText(inlineHTMLToJSON(inlineHTML), currentlyEditing.range, newTextSpan)

          let styleChangeRange = {
            start: currentlyEditing.range.start,
            end: afterInputRangeSelection.range.end
          }

          if (currentlyEditing.newlyActiveStyles) {
            currentlyEditing.newlyActiveStyles.forEach(newActiveStyle => {
              newInlineJSON = addStyle(newInlineJSON, newActiveStyle.type, styleChangeRange, newActiveStyle.args || {})
            })
          }

          if (currentlyEditing.newlyInactiveStyles) {
            currentlyEditing.newlyInactiveStyles.forEach(newInactiveStyle => {
              newInlineJSON = removeStyle(newInlineJSON, newInactiveStyle.type, styleChangeRange)
            })
          }

          return inlineJSONToHTML(newInlineJSON)
        })
      })

      this.props.editState.setCurrentlyEditing({...currentlyEditing, range: afterInputRangeSelection.range, newlyActiveStyles: [], newlyInactiveStyles: []})
    }
  }

  handlePaste(e) {
    e.preventDefault()

    const currentlyEditing = this.props.editState.currentlyEditing

    const pasteData = e.clipboardData.getData('text/plain')
    if (
      pasteData &&
      currentlyEditing &&
      currentlyEditing.uid &&
      currentlyEditing.key &&
      currentlyEditing.range
    ) {
      const lines = R.reject(R.isEmpty, R.map(R.trim, (R.split('\n', pasteData))))
      const pasteString = R.join(" ", lines)

      this.props.editState.modifyEditJSON(json => {
        return modifyNodeValueByUIDAndKey(json, currentlyEditing.uid, currentlyEditing.key, inlineHTML => {
          let newInlineJSON = replaceText(inlineHTMLToJSON(inlineHTML), currentlyEditing.range, pasteString)

          return inlineJSONToHTML(newInlineJSON)
        })
      })

      const newRangeStart = currentlyEditing.range.start + pasteString.length
      const newSelectionRange = {
        start: newRangeStart,
        end: newRangeStart
      }

      this.props.editState.setCurrentlyEditing({...currentlyEditing, range: newSelectionRange, newlyActiveStyles: [], newlyInactiveStyles: []})
    }
  }

  isEditingByProps(props) {
    let currentlyEditing = props.editState.currentlyEditing
    return currentlyEditing && currentlyEditing.uid === props.uid && currentlyEditing.key === props.contentKey && !currentlyEditing.selectionMenu
  }

  updateSelectionIfRelevantByProps(props, shouldDelay=false) {
    let currentlyEditing = props.editState.currentlyEditing

    if (this.isEditingByProps(props)) {
      let currentDocRangeSelection = getDocRangeSelection()
      if (!areEqualRangeSelections(currentDocRangeSelection, currentlyEditing)) {
        let setDocSelctionFunc = () => setDocSelection(currentlyEditing)
        if (shouldDelay) {
          setTimeout(setDocSelctionFunc, 10)
        } else {
          setDocSelctionFunc()
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate =
      this.props.content != nextProps.content ||
      this.isEditingByProps(this.props) != this.isEditingByProps(nextProps) ||
      this.props.editState.isEditing !== nextProps.editState.isEditing

    if (!shouldUpdate) { this.updateSelectionIfRelevantByProps(nextProps, true) }

    return shouldUpdate
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateSelectionIfRelevantByProps(this.props)
  }

  componentDidMount() {
    this.updateSelectionIfRelevantByProps(this.props)
  }

  setAsCurrentlyEditing() {
    this.props.editState.setCurrentlyEditing({uid: this.props.uid, range: {start: 0, end: 0}, key: this.props.contentKey})
  }

  render() {
    if (this.props.editState.isEditing && !this.props.preventEditing) {
      let content = this.props.content
      if (content.length === 0) {
        if (!this.isEditingByProps(this.props)) {
          return <span className="doc-inline-text insert-text-here" onClick={this.setAsCurrentlyEditing.bind(this)}>Insert Text Here</span>
        } else {
          content = "&nbsp;"
        }
      }

      return (
        <span
          className="doc-inline-text mousetrap"
          data-uid={this.props.uid}
          data-contentkey={this.props.contentKey}
          dangerouslySetInnerHTML={{__html: content}}
          contentEditable
          onInput={this.handleInput}
          onPaste={this.handlePaste}
          autoCapitalize={"off"}
          autoComplete={"off"}
          autoCorrect={"off"}
          spellCheck={false}
          onClick={(e) => e.stopPropagation()}
          ref={this.ref}
        />
      )
    } else {
      return (
        <span
          className="doc-inline-text mousetrap"
          data-uid={this.props.uid}
          data-contentkey={this.props.contentKey}
          dangerouslySetInnerHTML={{__html: this.props.content || ""}}
          ref={this.ref}
        />
      )
    }
  }
}
