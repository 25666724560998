import React from 'react';

import classNames from 'classnames'

import GenDirectionalButtons from 'Gen/DirectionalButtons'
import GenMore from 'Gen/More'

import { fullURL } from 'src/url'

function filterKeys(object, keys) {
    var result = {};
    for (var key in object)
        if (keys.indexOf(key) > -1)
            result[key] = object[key];
    return result;
}

export default class GenList extends React.Component {
  // React.PropTypes
  // propTypes: {
  //   // ids:
  //   // notIds:
  //   // itemsURL:
  //   // search:
  //   // sortOptions:
  //   // filterOptions:
  // },
  //
  static defaultProps = {
    historyPersistance: false,
    defaultItemsPerPage: 12,
    shouldInfiniteScroll: true
  };

  constructor(props, context) {
    super(props, context);
    var defaults = {
      currentItems: null,
      itemsPerPage: props.defaultItemsPerPage,
      pageNumber: 0,
      gettingNewPage: false,
      itemsLastRecieved: null,
      searchValue: "",
      searchTimer: null,
      sortValue: null,
      sortDirection: null,
      activeFilters: {},
      activeWhereFilters: {},
      activeCheckboxFilters: [],
      infiniteScrollRandomKey: Math.floor(Math.random() * 1000000)
    };

    const history = this.props.historyPersistance && this.getHistoryState();

    this.state = {
      ...defaults,
      ...history
    };

    this.refreshList = this.refreshList.bind(this)
    this.refreshItem = this.refreshItem.bind(this)
  }

  componentDidMount() {
    this.getUpdatedList();

    if (this.props.shouldInfiniteScroll) {
      window.addEventListener("scroll", this.checkVisibilityForInfiniteScroll);
      setTimeout(this.checkVisibilityForInfiniteScroll,500);
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearTimeout(this.state.searchTimer);
    }

    window.removeEventListener("scroll", this.checkVisibilityForInfiniteScroll);
  }

  // search=string
  // page=int
  // sort_value=string
  // sort_order=string
  //
  getHistoryState = () => {
    let state = history.state;
    if (state === null) {
      state = {};
    }
    return(filterKeys(state, ["searchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeWhereFilters", "activeCheckboxFilters"]));
  };

  setHistoryState = () => {
    const currentState = history.state;
    const newPartial = filterKeys(this.state, ["searchValue", "sortValue", "sortDirection", "pageNumber", "activeFilters", "activeWhereFilters", "activeCheckboxFilters"]);
    const newState = {...currentState, ...newPartial};

    history.replaceState(newState, document.title);
  };

  handleSearchChange = (e) => {
    this.setState({searchValue: e.target.value,
                   currentItems: null,
                   gettingNewPage: false,
                   pageNumber: 0});

    if (this.state.searchTimer) { clearTimeout(this.state.searchTimer); }
    this.setState({searchTimer: setTimeout(this.updateSearchWithTimer, 1000)});
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  updateSearchWithTimer = () => {
    // this.updateUSC();
    this.getUpdatedList();
    this.setState({timer: null});
  };

  updateSort = (newSortValue, newSortDirection) => {
    this.setState({ sortValue: newSortValue,
                    sortDirection: newSortDirection,
                    currentItems: null,
                    gettingNewPage: false,
                    pageNumber: 0 }, this.getUpdatedList);
  };

  handleFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveFilters = that.state.activeFilters;
      let newActiveFilters = {};
      for (var attr in currentActiveFilters) {
        if (attr != newKey) {
          newActiveFilters[attr] = currentActiveFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveFilters[newKey] = newValue;
      }

      that.setState({ activeFilters: newActiveFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  handleWhereFilterChange = (that, newKey) => {
    return(function(newSelect){
      let newValue = newSelect.target.value;

      let currentActiveWhereFilters = that.state.activeWhereFilters;
      let newActiveWhereFilters = {};
      for (var attr in currentActiveWhereFilters) {
        if (attr != newKey) {
          newActiveWhereFilters[attr] = currentActiveWhereFilters[attr];
        }
      }

      if (newValue != "All") {
        newActiveWhereFilters[newKey] = newValue;
      }

      that.setState({ activeWhereFilters: newActiveWhereFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  handleCheckboxFilterChange = (that, filterName) => {
    return(function(event){
      let isChecked = event.target.checked;

      var newActiveCheckboxFilters = that.state.activeCheckboxFilters;
      if (isChecked) {
        newActiveCheckboxFilters = newActiveCheckboxFilters.concat([filterName])
      } else {
        newActiveCheckboxFilters = newActiveCheckboxFilters.filter((val) => val != filterName)
      }

      that.setState({ activeCheckboxFilters: newActiveCheckboxFilters,
                      currentItems: null,
                      gettingNewPage: false,
                      pageNumber: 0 }, that.getUpdatedList);
    });
  };

  moreClick = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1,
                    gettingNewPage: true}, this.getAdditionalPage);
  };

  dataForQuery = () => {
    let data = {};

    if (this.props.ids) {
      data.ids = this.props.ids;
    }

    if (this.props.notIds) {
      data.not_ids = this.props.notIds;
    }

    if (this.state.searchValue && this.state.searchValue.trim() !== "") {
      data.search = this.state.searchValue;
    }

    if (this.state.sortValue) {
      data.sort_order = (this.state.sortDirection === "descending") ? "DESC" : "ASC";
      data.sort_by = this.state.sortValue;
    }


    if (this.state.itemsPerPage) {
      if (this.state.currentItems === null) {
        data.page = 0;
        data.per_page = (this.state.pageNumber + 1) * this.state.itemsPerPage;
      } else {
        data.page = this.state.pageNumber;
        data.per_page = this.state.itemsPerPage;
      }
    }

    let filterValues = [];
    for(var key in this.state.activeFilters) {
      filterValues.push(this.state.activeFilters[key]);
    }

    let activeCheckboxFilters = this.state.activeCheckboxFilters.map(checkboxFilterKey => {
      return this.props.checkboxFilterOptions.find((checkboxFilterOption) => checkboxFilterOption["key"] === checkboxFilterKey)
    })

    let regularCheckboxFilters = activeCheckboxFilters.filter(filter => filter.filter)
    regularCheckboxFilters.forEach(regularCheckboxFilter => {
      filterValues.push(regularCheckboxFilter.filter)
    });

    if (filterValues.length > 0) {
      data.filters = filterValues;
    }

    let whereValues = [];
    let whereCheckboxFilters = activeCheckboxFilters.filter(filter => filter.whereFilter)
    whereCheckboxFilters.forEach(whereCheckboxFilter => {
      whereValues.push(whereCheckboxFilter.whereFilter)
    });

    for(var key in this.state.activeWhereFilters) {
      let whereFilterOption = this.props.whereFilterOptions.find((wfo) => {return(wfo.title === key)})
      whereValues.push(whereFilterOption["options"][this.state.activeWhereFilters[key]]);
    }
    if (whereValues.length > 0) {
      data.where = JSON.stringify(whereValues);
    }

    return data;
  };

  getPage = (successCallback) => {
    $.ajax({
      type: "GET",
      url: fullURL(this.props.itemsURL),
      dataType: 'json',
      data: this.dataForQuery(),
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  };

  getItem = (itemId, successCallback) => {
    $.ajax({
      type: "GET",
      url: fullURL(this.props.itemsURL),
      dataType: 'json',
      data: {ids: [itemId]},
      contentType: 'application/json',
      success: successCallback.bind(this),
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
      }
    });
  }

  getUpdatedList = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      if (result) { this.setState({currentItems: result, itemsLastRecieved: result.length}); }
      if (this.props.shouldInfiniteScroll) {
        setTimeout(this.checkVisibilityForInfiniteScroll,100);
      }
    });
  };

  getAdditionalPage = () => {
    this.getPage(function(result) {
      if (this.props.historyPersistance) { this.setHistoryState(); }
      this.setState({currentItems: (this.state.currentItems || []).concat(result),
                     itemsLastRecieved: result.length,
                     gettingNewPage: false});
      if (this.props.shouldInfiniteScroll) {
        setTimeout(this.checkVisibilityForInfiniteScroll,100);
      }
    });
  };

  refreshList = () => {
    this.setState({ currentItems: null,
                    gettingNewPage: false,
                    pageNumber: 0 }, this.getUpdatedList);
  };

  refreshItem = (itemId) => {
    this.getItem(itemId, function(result) {
      let returnedItem = result[0]
      let indexOfItem = this.state.currentItems.findIndex((currentItem) => currentItem.id === itemId)

      if (returnedItem && indexOfItem >= 0) {
        let modifiedCurrentItems = [
          ...this.state.currentItems.slice(0, indexOfItem),
          returnedItem,
          ...this.state.currentItems.slice(indexOfItem+1)
        ]
        this.setState({ currentItems: modifiedCurrentItems })
      }
    });
  }

  checkVisibilityForInfiniteScroll = () => {
    let element = document.getElementById(this.state.infiniteScrollRandomKey)
    if (element) {
      let elementRect = element.getBoundingClientRect();
      let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      let isVisible =  !(elementRect.bottom < 0 || elementRect.top - viewHeight >= 0);

      if (isVisible) {
        this.moreClick()
      }
    }
  };

  render() {
    let controlPanel = null

    if (this.props.search || this.props.sortOptions || this.props.checkboxFilterOptions) {
      let searchBox = null
      if (this.props.search) {
        searchBox = <form className="gen-list-search-bar" role="search">
          <input
            type="search"
            placeholder={this.props.searchPlaceHolder || "Search..."}
            value={this.state.searchValue}
            onChange={this.handleSearchChange}
            onKeyPress={this.handleSearchKeyPress}
          />
        </form>;
      } else {
        searchBox = null;
      }

      let checkboxFilters = null
      if (this.props.checkboxFilterOptions) {
        checkboxFilters = <div className="gen-list-checkbox-filters">
          {this.props.checkboxFilterOptions.map((filter) => {
            let filterOut = <div key={filter.key}>
              <label>
                <input
                    type="checkbox"
                    value={filter.key}
                    checked={this.state.activeCheckboxFilters.includes(filter.key)}
                    onChange={this.handleCheckboxFilterChange(this, filter.key)}
                />
                {filter.label}
              </label>
            </div>

            return( filterOut );
          }, this)}
        </div>
      }

      let sortOptions = null
      if (this.props.sortOptions) {
        sortOptions = <GenDirectionalButtons
                          buttons={this.props.sortOptions}
                          selectedValue={this.state.sortValue}
                          selectedDirection={this.state.sortDirection}
                          onUpdate={this.updateSort}/>;
      }

      controlPanel = <div className="gen-list-control-panel">
        <div className="gen-list-control-set">
          {searchBox}
          {checkboxFilters}
        </div>
        <div className="gen-list-control-set">
          {sortOptions}
        </div>
      </div>;
    }

    let filterPanel
    if (this.props.filterOptions || this.props.whereFilterOptions) {
      var activeFilters = this.state.activeFilters;
      var activeWhereFilters = this.state.activeWhereFilters;
      filterPanel = <div className="gen-list-filter-panel">
        {this.props.filterOptions && this.props.filterOptions.map(function(filter){
          let filterOut = <div key={filter.title} className="gen-list-filter">
            <h5>{filter.title}</h5>
            <select defaultValue={activeFilters[filter.title] || "All"} onChange={this.handleFilterChange(this, filter.title)}>
              <option value="All">All</option>
              {filter.options.map(function(option){
                return(
                  <option key={option} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
          </div>;

            return( filterOut );
          }, this)}
        {this.props.whereFilterOptions && this.props.whereFilterOptions.map(function(whereFilter){
          whereFilterOut = <div key={whereFilter.title} className="gen-list-filter">
            <h5>{whereFilter.title}</h5>
            <select defaultValue={activeWhereFilters[whereFilter.title] || "All"} onChange={this.handleWhereFilterChange(this, whereFilter.title)}>
              <option value="All">All</option>
              {Object.keys(whereFilter.options).map(function(option_key){
                return(
                  <option key={option_key} value={option_key}>
                    {option_key}
                  </option>
                );
              })}
            </select>
          </div>;

            return( filterOut );
          }, this)}
      </div>;
    }

    let listItems;
    if (this.state.currentItems !== null) {
      if (this.state.currentItems.length === 0) {
        const hasActiveSearchOrFiltering =
          this.state.searchValue.length > 0 ||
          Object.keys(this.state.activeFilters).length > 0 ||
          Object.keys(this.state.activeWhereFilters).length > 0 ||
          this.state.activeCheckboxFilters.length > 0

        listItems = (
          <div className="gen-list-no-items">
            { this.props.noItemsMessage && !hasActiveSearchOrFiltering ? <h5>{this.props.noItemsMessage}</h5> : <h1>No Items</h1> }
          </div>
        );
      } else {
        listItems = this.state.currentItems.map(item => this.props.itemView(item, {refreshList: this.refreshList, refreshItem: this.refreshItem}));
        listItems = (
          <div className={classNames("gen-list-items", {"gen-list-grid": this.props.gridView})}>
            {this.state.currentItems.map(item => this.props.itemView(item, {refreshList: this.refreshList, refreshItem: this.refreshItem}))}
          </div>
        );
      }
    }

    return(
      <div className="gen-list">
        {controlPanel}
        {filterPanel}
        {listItems}
        {!this.props.shouldDisableMoreButton &&
          <GenMore
            isSyncing={this.state.currentItems === null || this.state.gettingNewPage}
            itemsPerPage={this.state.itemsPerPage}
            lastPageLength={this.state.itemsLastRecieved}
            readListPage={this.moreClick}
            shouldInfiniteScroll={this.props.shouldInfiniteScroll}
          />
        }
      </div>
    );
  }
}
