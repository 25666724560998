import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'

export default class DocRatingBar extends React.Component {
  onClick = (clickValue, e) => {
    e.stopPropagation();

    if (this.props.inactiveBoolean) {return null;}

    this.props.currentUser.addState({[this.props.uid]: {"value": this.props.maxValue - clickValue}});
  };

  render() {
    let stars = [];
    for (let i = 0; i < this.props.maxValue; i++) {
      stars.push(
        <span
          className={classNames("doc-rating-bar-star", {"doc-rating-bar-star-active": i >= this.props.maxValue - this.props.value})}
          key={i}
          onClick={this.onClick.bind(this, i)}
        >
          ☆
        </span>
      );
    }

    return (
      <div className={classNames("doc-rating-bar", {"inactive": this.props.inactiveBoolean})}>
        {stars}
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
