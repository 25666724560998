import React from 'react';

import GenButton from 'Gen/Button'
import GenLoadingSpinner from 'Gen/LoadingSpinner'

export default class GenMore extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      infiniteScrollRandomKey: Math.floor(Math.random() * 1000000)
    }
  }

  componentDidMount() {
    if (this.props.shouldInfiniteScroll) {
      window.addEventListener("scroll", this.checkVisibilityForInfiniteScroll)
      this.checkVisibilityTimer = setInterval(this.checkVisibilityForInfiniteScroll,500)
    }
  }

  componentWillUnmount() {
    if (this.props.shouldInfiniteScroll) {
      window.removeEventListener("scroll", this.checkVisibilityForInfiniteScroll)
      clearTimeout(this.checkVisibilityTimer)
    }
  }

  checkVisibilityForInfiniteScroll = () => {
    let element = document.getElementById(this.state.infiniteScrollRandomKey)
    if (element) {
      let elementRect = element.getBoundingClientRect()
      let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
      let isVisible = !(elementRect.bottom < 0 || elementRect.top - viewHeight >= 0)

      if (isVisible) {
        this.props.readListPage()
      }
    }
  }

  render() {
    if (this.props.isSyncing) {
      return <GenLoadingSpinner/>
    } else if (this.props.lastPageLength < this.props.itemsPerPage){
      return <div className="gen-list-pagination flex justify-center">
        <GenButton name="End" highlighted disabled/>
      </div>
    } else if (this.props.shouldInfiniteScroll) {
      return <div className="gen-list-pagination">
        <div id={this.state.infiniteScrollRandomKey} className="infinite-scroll-area"></div>
      </div>
    } else {
      return <div className="gen-list-pagination flex justify-center">
        <GenButton name="More" onClick={() => this.props.readListPage()} highlighted/>
      </div>
    }
  }
}
