import React from 'react';

import classNames from 'classnames'

export const switchModes = {
  ON: "ON",
  OFF: "OFF",
  MIXED: "MIXED"
}

export default class GenSwitch extends React.Component {
  render() {
    return <span
                className="gen-switch"
                onClick={ () => {
                  switch (this.props.value) {
                    case switchModes.ON:
                      this.props.onClick(switchModes.OFF)
                      break;
                    case switchModes.OFF:
                      this.props.onClick(this.props.includeMixed ? switchModes.MIXED : switchModes.ON)
                      break;
                    case switchModes.MIXED:
                    default:
                      this.props.onClick(switchModes.ON)
                  }}
                }>
                <span className={
                  classNames("gen-switch-slider", `${this.props.value}`)
                }></span>
           </span>
  }
}
