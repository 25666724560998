import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocImage from 'Doc/Image'

export default class DocImageGallery extends React.Component {
  render() {
    let images = this.props.images.map((image) => {
      const passToEveryNode = {
        currentDocument: this.props.currentDocument,
        currentVersion: this.props.currentVersion,
        currentUser: this.props.currentUser,
        displayState: this.props.displayState,
        editState: this.props.editState,

        currentDocumentSetDocumentRelation: this.props.currentDocumentSetDocumentRelation,
        currentRoleDefinition: this.props.currentRoleDefinition,
      }

      return (
        <div className="doc-image-gallery-slide" key={image.uid}>
          <DocImage
            {...image}
            {...passToEveryNode}
            inGallery
          />
        </div>
      )
    });

    return (
      <div className="doc-image-gallery">
        <div className="doc-image-gallery-scroller">
          {images}
        </div>
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
