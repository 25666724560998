import React from 'react';

import DocBookmark from 'Doc/Bookmark'
import DocInlineText from 'Doc/InlineText'

export default class DocParagraph extends React.Component {
  render() {
    return (
      <div className="doc-paragraph">
        <p>
          <DocInlineText content={this.props.content} uid={this.props.uid} contentKey="content" editState={this.props.editState}/>
        </p>
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
