import React from 'react';

import GenList from 'Gen/List'

import CategorySummary from 'Category/Summary'

export default class CategoryList extends React.Component {
  render() {
    return (
      <GenList
        shouldDisableMoreButton
        itemsURL={"/categories"}
        itemView={(category, genListCallbacks) => {
          return (<CategorySummary
            key={category.id}
            rootDocumentSetId={this.props.rootDocumentSetId}
            {...category}
            currentUser={this.props.currentUser}
            currentRoleDefinition={this.props.currentRoleDefinition}
            positionCallback={genListCallbacks.refreshList}
            itemRefreshCallback={genListCallbacks.refreshItem}
          />);
        }}
        search={true}
      />
    );
  }
}
