import React from 'react';

import GenList from 'Gen/List'

import DocumentSetSummary from 'DocumentSet/Summary'

export default class DocumentSetList extends React.Component {
  render() {
    return(
        <GenList
          itemsURL={"/document_sets/" + this.props.document_set_id + "/document_sets"}
          itemView={documentSet => <DocumentSetSummary documentSet={documentSet} key={documentSet.id}/>}
          noItemsMessage={this.props.current_role_definition?.name === "Learner" && "You have not been added to any courses yet. If you believe this is a mistake, please contact your instructor."}
          search
          sortOptions={[
                        {
                          "title": "Name",
                          "value": "document_sets.name"
                        },
                        {
                          "title": "Owner",
                          "value": "users.last_name"
                        }
                      ]}
          checkboxFilterOptions={this.props.shows_ownership_checkbox && [
                                  {
                                    "key": "owner",
                                    "whereFilter": ["=", "document_sets.owner_id", this.props.user_id],
                                    "label": "Only courses that I own"
                                  }
                                ]}
        />
    );
  }
}
