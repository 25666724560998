import React from 'react';

import GenPostButton from 'Gen/PostButton'
import GenSelectList from 'Gen/SelectList'

export default class DocumentSetUserPermissionCreateSelectList extends React.Component {
  state = {
    selectedGroupRoleDefinitionUserIds : [],
    loading: false
  };

  handleGroupRoleSelectionChange = (newSelection) => {
    this.setState({selectedGroupRoleDefinitionUserIds : newSelection});
  };

  onSubmitSuccess = () => {
    var edit_url = window.location.href;
    var update_url = edit_url.replace("/new_batch_list", "");

    Turbolinks.visit(update_url);
  };

  handleSubmitButton = () => {
    if (this.state.selectedGroupRoleDefinitionUserIds.length === 0) {
      alert("No Members Selected! You must select at least one user to add from list.");
      return(null);
    }

    let data = {groupRoleDefinitionUserIds: this.state.selectedGroupRoleDefinitionUserIds};

    let edit_url = window.location.href;
    let update_url = edit_url.replace("new_", "create_");

    this.setState({loading: true});

    $.ajax({
      type: "POST",
      url: update_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSubmitSuccess,
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        this.setState({loading: false});
      }
    });
  };

  render() {
    let groupRoleSelectionView = <GenSelectList
      selected={this.state.selectedGroupRoleDefinitionUserIds}
      onSelectionChange={this.handleGroupRoleSelectionChange}
      itemView={function(role){
        return(
          <div key={role.id}>
            <span>{role.user.email} -- {role.user.first_name} {role.user.last_name} -- {role.role_definition.name}</span>
          </div>);
      }}

      itemsURL={"/document_sets/"+ this.props.document_set.id +"/document_set_user_permissions/new_batch_list"}
      search={true}
      sortOptions={[
                    {
                      "title": "First Name",
                      "value": "first_name",
                    },
                    {
                      "title": "Last Name",
                      "value": "last_name"
                    },
                    {
                      "title": "Email",
                      "value": "email"
                    }]}
    />;

    return(
      <div className="document-set-user-permissions-select-list">
        <div className="submit-panel">
          <h1>Add Members</h1>

          <GenPostButton
            name="Add Members"
            key="AddMembers"
            highlighted={true}
            disabled={this.state.selectedGroupRoleDefinitionUserIds.length === 0}
            url={window.location.href.replace("new_", "create_")}
            data={{groupRoleDefinitionUserIds: this.state.selectedGroupRoleDefinitionUserIds}}
          />
        </div>
        <div>
          {groupRoleSelectionView}
        </div>
      </div>
    );
  }
}
