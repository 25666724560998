import React from "react";

import GenButton from "../Gen/Button";
import GenModal from "../Gen/Modal";

import ActivityReviewSummary from "./ReviewSummary";

import { documentDisplayTitle } from 'src/document_display_title'

export default class ActivityReviewModal extends React.Component {
  constructor(props) {
    super(props);

    const {
      activityUserState,
    } = props;

    this.state = {
      reviewComment: activityUserState.review_comment,
    };
  }

  postActivityUserStateData(id, data) {
    const {
      afterSave,
    } = this.props;

    fetch(`/activity_user_states/${id}`, {
      method: "PATCH",
      body: JSON.stringify({activity_user_state: data}),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(() => { afterSave(); })
      .catch(() => { alert("There was an error saving your review."); });
  }

  render() {
    const {
      reviewComment,
    } = this.state;

    const {
      activity,
      activityUserState,
      document,
      user,
      afterCancel,
    } = this.props;

    return (
      <GenModal open className="activity-review-modal">
        <div className="gen-modal-panel">
          <div className="review-modal-header">
            <h1>Review {activity.name} Skill</h1>
            <p style={{marginBottom: "5px"}}><strong>Case:</strong> <a href={`/documents/${document.id}`} target="_blank" rel="noreferrer">{documentDisplayTitle(document)}</a></p>
            <p style={{marginBottom: "5px"}}><strong>Student&apos;s Name:</strong> {user.first_name} {user.last_name}</p>
            <p style={{marginBottom: "5px"}}><strong>Student&apos;s Email:</strong> {user.email}</p>
          </div>

          <hr />

          <div className="review-modal-feedback">
            <div>
              <p>Feedback:</p>
              <textarea
                style={{resize: "none"}}
                rows="4"
                value={reviewComment}
                disabled={activityUserState.reviewed}
                onChange={(e) => { this.setState({reviewComment: e.target.value}); }}
              />
            </div>
            <div>
              <div className="review-buttons">
                <GenButton
                  name="Save Draft"
                  highlighted
                  disabled={activityUserState.reviewed}
                  onClick={() => {
                    this.postActivityUserStateData(activityUserState.id, {
                      review_comment: reviewComment,
                    });
                  }}
                />
                <GenButton
                  name="Submit Review"
                  highlighted
                  disabled={activityUserState.reviewed}
                  onClick={() => {
                    this.postActivityUserStateData(activityUserState.id, {
                      review_comment: reviewComment,
                      reviewed_at: (new Date()).toISOString(),
                    });
                  }}
                />
                <GenButton
                  name="Close"
                  highlighted
                  onClick={afterCancel}
                />
              </div>
            </div>
          </div>

          <hr />

          <div className="activity">
            <ActivityReviewSummary
              activity={activity}
              activityUserState={activityUserState}
            />
          </div>

        </div>
      </GenModal>
    );
  }
}
