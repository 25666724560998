import React from "react";

import connectToList from 'store/list';

import {capitalize} from 'lodash';
import pluralize from 'pluralize';

import GenControlsBar from '../Gen/ControlsBar';
import GenListDisplay from "../Gen/ListDisplay";
import GenMore from '../Gen/More';
import GenSearchBox from '../Gen/SearchBox';
import GenSelectBar from "../Gen/SelectBar";

const ACTIVITIES_ENDPOINT = 'activities'
const CATEGORIES_ENDPOINT = 'categories'

class DocumentSetNewBatchPreset extends React.Component {
  componentDidMount() {
    this.props.activities.mountList(ACTIVITIES_ENDPOINT)
    this.props.categories.mountList(CATEGORIES_ENDPOINT)
  }

  activitiesRender() {
    return (
      <div>
        <GenControlsBar>
          <GenSearchBox
            {...this.props.activities}
          />
        </GenControlsBar>
        <GenSelectBar
          {...this.props.activities}
          itemType={"skill(s)"}
        />
        <GenListDisplay
          {...this.props.activities}
          renderItem={(item) => <p>{item.name}</p>}
        />
        <GenMore
          {...this.props.activities}
          shouldInfiniteScroll
        />
      </div>
    )
  }

  categoriesRender() {
    return (
      <div>
        <GenControlsBar>
          <GenSearchBox
            {...this.props.categories}
          />
        </GenControlsBar>
        <GenSelectBar
          {...this.props.categories}
          itemType={"future course(s)"}
        />
        <GenListDisplay
          {...this.props.categories}
          renderItem={(item) => <p>{item.name}</p>}
        />
        <GenMore
          {...this.props.categories}
          shouldInfiniteScroll
        />
      </div>
    )
  }

  render() {
    return (
      <div className="two-columns">
        <div className="column">
          <div className="panel">
            <h3>Skills To Add</h3>
            <input type='hidden' name='activity_ids' value={JSON.stringify(this.props.activities.selected.map((item) => item.id))} />
          </div>
          {this.activitiesRender()}
        </div>
        <div className="column">
          <div className="panel">
            <h3>{capitalize(pluralize(i18n.t("category")))} To Use</h3>
            <input type='hidden' name='category_ids' value={JSON.stringify(this.props.categories.selected.map((item) => item.id))} />
          </div>
          {this.categoriesRender()}
        </div>
      </div>
    );
  }
}

export default connectToList(DocumentSetNewBatchPreset, ['activities', 'categories']);
