import React from 'react'

export default class DocumentUserSummary extends React.Component {
  render() {
    return(
      <div className="panel">
        <h1>{this.props.documentUser.user.first_name} {this.props.documentUser.user.last_name}</h1>
        <p>{this.props.documentUser.user.email}</p>
      </div>
    )
  }
}
