import React from 'react';

export default class UserSummary extends React.Component {
  render() {
    return(
      <div className="panel">
        <h1 className="full_name">
          <a href={"/users/" + this.props.user.id}>
            <span className="first_name">{this.props.user.first_name}</span> <span className="last_name">{this.props.user.last_name}</span>
          </a>
        </h1>
        <p className="email">{this.props.user.email}</p>
      </div>
    );
  }
}
