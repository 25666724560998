import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'
import DocInlineText from 'Doc/InlineText'

import { performActions } from 'src/doc_actions'

export default class DocButton extends React.Component {
  onClick = () => {
    if (this.props.inactiveBoolean) {return null;}

    performActions(this.props.onClick, this.props.currentUser)
  };

  render() {
    return (
      <div className={classNames("doc-button-slice", {"inactive": this.props.inactiveBoolean})}>
        <button className="text-white bg-sky-600 hover:bg-sky-500 p-2 doc-button" onClick={this.onClick}>
          <DocInlineText content={this.props.title} uid={this.props.uid} contentKey="title" editState={this.props.editState} preventEditing/>
        </button>
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
