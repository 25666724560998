import React from 'react';

import GenList from 'Gen/List'

import DocumentSetDocumentRelationSummary from 'DocumentSetDocumentRelation/Summary'

import {documentListDefaults} from 'Document/ListDefaults'

export default class DocumentSetDocumentRelationList extends React.Component {
  render() {
    const {
      document_set,
      isLearner,
      show_status,
    } = this.props;

    return(
        <GenList {...documentListDefaults}
          gridView
          itemView={(documentSetDocumentRelation) => (
            <DocumentSetDocumentRelationSummary
              key={documentSetDocumentRelation.id}
              documentSetDocumentRelation={documentSetDocumentRelation}
              show_status={show_status}
              isLearner={isLearner}
              documentSet={document_set}
            />
          )}
          itemsURL={"/document_sets/" + document_set.id + "/document_set_document_relations"}
        />
    );
  }
}
