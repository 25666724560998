import React from 'react';

import DocInlineText from 'Doc/InlineText'

export default class DocListItem extends React.Component {
  render() {
    return (
      <li className="doc-list-item">
        <DocInlineText content={this.props.content} uid={this.props.uid} contentKey="content" editState={this.props.editState}/>
      </li>
    )
  }
}
