import React from 'react';

import GenList from 'Gen/List'

import GroupSummary from 'Group/Summary'

export default class GroupList extends React.Component {
  render() {
    var domain = this.props.domain;
    return(
        <GenList
          itemView={group => {
            return <GroupSummary
              group={group}
              key={group.id}
              domain={this.props.domain}
              shouldShowExpiration={this.props.isAdmin}
            />
          }}
          itemsURL={this.props.groups_path || "/groups"}
          search={true}
          sortOptions={[
                        {
                          "title": "Name",
                          "value": "groups.name"
                        }
                      ]}
        />
    );
  }
}
