import React from 'react';

export default class CaptureSummary extends React.Component {
  render() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let created_at_date = new Date(this.props.document.created_at);
    let formatted_date = created_at_date.toLocaleDateString("en-US", options);

    return(
      <a href={"/documents/" + this.props.document.id} style={{width: "100%"}}>
        <div className="capture_summary_row">
          <div className="capture_summary_header">
            <h1> {this.props.document.title} </h1>
          </div>
          <div className="capture_summary_details">
            <p> <strong> Created: </strong> {formatted_date} <br/> </p>
            <p> <strong> Status: </strong> {this.props.document.pretty_status} </p>
            <p> <strong> Patient Age: </strong> {this.props.document.pretty_age} </p>
          </div>
        </div>
      </a>
    );
  }
}
