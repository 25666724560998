import React from 'react'

export default class VersionSummary extends React.Component {
  render() {
    return(
      <div className="panel">
        <h1 className="name">
          <a href={"/versions/" + this.props.version.id}>
            {this.props.version.updated_at}
          </a>
        </h1>
        <p>ID: {this.props.version.id}</p>
      </div>
    )
  }
}
