import React from 'react'

import Moment from 'moment'

export default class GroupSummary extends React.Component {
  render() {
    var port = "";
    if (this.props.domain === "lvh.me") {
      port = ":3000";
    }

    let expirationView
    if (this.props.group.expiration_date && this.props.shouldShowExpiration) {
      let momentTime = Moment(this.props.group.expiration_date)
      let inThePast = momentTime < (new Date())
      let formattedDate = momentTime.format('MMMM Do YYYY h:mm a')

      if (inThePast) {
        expirationView = <span style={{color: 'red'}}>Expired At: {formattedDate}</span>
      } else {
        expirationView = <span style={{color: 'black'}}>Expires At: {formattedDate}</span>
      }
    }

    return(
      <div className="gen-select-item">
        <h1 className="name"><a href={"//" + this.props.group.subdomain + "." + this.props.domain + port}>{this.props.group.name}</a></h1>
        {expirationView}
        <p className="description">{this.props.group.description}</p>
      </div>
    );
  }
}
