import React from 'react';

export default class GenSearchBox extends React.Component {
  componentWillUnmount() {
    if (this.searchTimer) { clearTimeout(this.searchTimer) }
  }

  handleSearchChange = (e) => {
    this.props.setListSearch(e.target.value)

    if (this.searchTimer) { clearTimeout(this.searchTimer) }
    this.searchTimer = setTimeout(this.updateSearchWithTimer, 750)
  };

  handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (this.searchTimer) { clearTimeout(this.searchTimer) }
      this.updateSearchWithTimer()
      e.preventDefault()
    }
  };

  updateSearchWithTimer = () => {
    this.props.resetListPages()
    this.props.readListPage()
    this.searchTimer = null
  };

  render() {
    return (
      <form className="gen-search-box" role="search">
        <input
          type="search"
          placeholder="Search..."
          value={this.props.search || ""}
          onChange={this.handleSearchChange}
          onKeyPress={this.handleSearchKeyPress}
        />
      </form>
    )
  }
}
