import React from 'react';

export default class GenLoadingSpinner extends React.Component {
  render() {
    let classes = "gen-loading-spinner";

    if (this.props.highlighted) {
      classes += " highlighted";
    }

    return(
      <div className={classes}>
        <div className="bg-sky-600 gen-loading-spinner-bounce-1" />
        <div className="bg-sky-600 gen-loading-spinner-bounce-2" />
        <div className="bg-sky-600 gen-loading-spinner-bounce-3" />
      </div>
    );
  }
}
