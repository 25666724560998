import React from 'react';

// GenCheckbox :: {
//   title: String,
//   value: Any
//   onChange: function
// }

export default class GenCheckboxFilter extends React.Component {
  render() {
    const {
      title,
      value,
      onChange
    } = this.props

    return(
      <div className="gen-checkbox-filter mb-2">
        <label>
          <input
            id={title}
            name={title}
            type="checkbox"
            checked={value || false}
            onChange={() => onChange(!value)}
          />
          {title}
        </label>
      </div>
    )
  }
}
