import React from 'react';

import { fullURL } from 'src/url'

import { documentDisplayTitle } from 'src/document_display_title'

import connectToList from 'store/list'

import GenControlsBar from 'Gen/ControlsBar'
import GenMore from 'Gen/More'
import GenSearchBox from 'Gen/SearchBox'
import GenSortButtons from 'Gen/SortButtons'

class DocumentContributorList extends React.Component {
  componentDidMount() {
    const endpoint = this.props.institution_id ? `institutions/${this.props.institution_id}/document_contributors` : `contributors/${this.props.contributor_id}/document_contributors`

    this.props.documentContributors.mountList(endpoint)
  }

  render() {
    let items = (this.props.documentContributors.listData || []).map(item => {
      let title = documentDisplayTitle(item.document)

      if (this.props.can_open_documents) {
        title = <a href={`${this.props.root_url}documents/${item.document.id}`}>{title}</a>
      }

      return (
        <div className="gen-panel" key={item.id}>
          <div className="gen-panel-header">
            <div className="gen-panel-header-image">
              <img src={item.document.cover_image} alt={`Case ${item.document.id} Cover Image`}  width="150"/>
            </div>
            <div className="gen-panel-header-title">
              <h1>
                {title}
              </h1>
              {!this.props.institution_id && <>
                {item.author && <h4>Author</h4>}
                {item.editor && <h4>Editor</h4>}
                {item.conflict && <p>{item.conflict}</p>}
              </>}
            </div>
            <div className="gen-panel-header-right-content">
              <div className="list-stat-panels">
                <div className="list-stat-panel">
                  <h1>{item.document.cached_assignment_count.toLocaleString()}</h1>
                  <h5>Assignments</h5>
                </div>
                <div className="list-stat-panel">
                  <h1>{item.document.cached_view_count.toLocaleString()}</h1>
                  <h5>Views</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

    return (
      <div>
        <GenControlsBar>
          <GenSearchBox
            {...this.props.documentContributors}
          />
          <GenSortButtons
            {...this.props.documentContributors}
            sortOptions={[
              {
                "title": "Case #",
                "value": "documents.id"
              },
              {
                "title": "Title",
                "value": "documents.title"
              },
              {
                "title": "Assignments",
                "value": "documents.cached_assignment_count"
              },
              {
                "title": "Views",
                "value": "documents.cached_view_count"
              }
            ]}
          />
        </GenControlsBar>
        {items}
        <GenMore
          {...this.props.documentContributors}
          shouldInfiniteScroll
        />
      </div>
    )
  }
}

export default connectToList(DocumentContributorList, 'documentContributors')
