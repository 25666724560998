import React from 'react';

import classNames from 'classnames'

import DocBookmark from 'Doc/Bookmark'
import DocInlineText from 'Doc/InlineText'

export default class DocCheckbox extends React.Component {
  onChanged = (e) => {
    this.props.currentUser.addState({[e.target.id]: {"value": e.target.checked}});
  };

  onClick = (e) => {
    e.stopPropagation();
  };

  render() {
    return (
      <div className={classNames("doc-checkbox", {"inactive": this.props.inactiveBoolean})}>
        <input type="checkbox" id={this.props.uid} disabled={this.props.inactiveBoolean} checked={this.props.value} onChange={this.onChanged} onClick={this.onClick}/>
        <label htmlFor={this.props.uid} onClick={this.onClick}>
          <span className="doc-checkbox-outer"><span className="doc-checkbox-inner"></span></span>
          <span className="doc-checkbox-title">
            <DocInlineText content={this.props.content} uid={this.props.uid} contentKey="content" editState={this.props.editState}/>
          </span>
        </label>
        <DocBookmark {...this.props} />
      </div>
    )
  }
}
