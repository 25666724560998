import React from 'react';

import GenButton from 'Gen/Button'
import GenSelectList from 'Gen/SelectList'

import {documentListDefaults} from 'Document/ListDefaults'

import { documentDisplayTitle } from 'src/document_display_title'

export default class DocumentSetDocumentRelationDeleteSelectList extends React.Component {
  state = {
    selectedDocumentIds : [],
    loading: false
  };

  handleDocumentSelectionChange = (newSelection) => {
    this.setState({selectedDocumentIds : newSelection});
  };

  onSubmitSuccess = () => {
    var edit_url = window.location.href;
    var update_url = edit_url.replace("/document_set_document_relations/delete_batch_list", "");

    Turbolinks.visit(update_url);
  };

  handleSubmitButton = () => {
    if (this.state.selectedDocumentIds.length === 0) {
      alert("Nothing Selected! You must select at least one item to remove.");
      return(null);
    }

    let data = {documentIds: this.state.selectedDocumentIds};

    let edit_url = window.location.href;
    let update_url = edit_url.replace("delete_", "destroy_");

    this.setState({loading: true});

    $.ajax({
      type: "DELETE",
      url: update_url,
      dataType: 'json',
      data: JSON.stringify(data),
      contentType: 'application/json',
      success: this.onSubmitSuccess,
      error: (xhr, status, error) => {
        console.log(xhr.responseText);
        console.log(status);
        console.log(error);
        this.setState({loading: false});
      }
    });
  };

  render() {
    let documentSelectionView =  <GenSelectList
      {...documentListDefaults}
      selected={this.state.selectedDocumentIds}
      onSelectionChange={this.handleDocumentSelectionChange}
      itemsURL={"/document_sets/"+ this.props.document_set_id +"/document_set_document_relations/delete_batch_list"}
      itemView={function(document){
        return(
          <div key={document.id}>
            <span>{documentDisplayTitle(document)}</span>
          </div>);
      }}
    />;



    return(
      <div className="document-set-document-relations-select-list">
        <div className="submit-panel">
          <h1>Remove Cases From Course</h1>
          <GenButton
            name="Remove"
            highlighted={true}
            loading={this.state.loading}
            onClick={this.handleSubmitButton}
          />
        </div>
        <div>
          {documentSelectionView}
        </div>
      </div>
    );
  }
}
