import React from 'react';

import GenDirectionalButtons from 'Gen/DirectionalButtons'

export default class GenSortButtons extends React.Component {
  updateSort = (newSortValue, newSortDirection) => {
    (newSortDirection === "descending") ? "DESC" : "ASC"

    this.props.setListSort(newSortValue, newSortDirection === "descending" ? "DESC" : "ASC")
    this.props.resetListPages()
    this.props.readListPage()
  }

  render() {
    return (
      <GenDirectionalButtons
        buttons={this.props.sortOptions}
        selectedValue={this.props.sort_by}
        selectedDirection={this.props.sort_order === "DESC" ? "descending" : "ascending"}
        onUpdate={this.updateSort.bind(this)}
      />
    )
  }
}
